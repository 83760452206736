var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "main" }, [
    _c(
      "div",
      { staticStyle: { "justify-self": "end" } },
      [
        _vm._m(0),
        _c(
          "V3Button",
          {
            staticStyle: {
              color: "black",
              width: "56px",
              "background-color": "white"
            },
            on: { click: _vm.closeBoard }
          },
          [_vm._v(" Close ")]
        )
      ],
      1
    ),
    _c("div", { staticClass: "sidebar" }, [
      _vm._v(" Drawing Tools "),
      _c(
        "div",
        { staticClass: "tools-container" },
        [
          _c(
            "V3Button",
            {
              attrs: { active: _vm.action === "freehand" },
              on: {
                click: function($event) {
                  return _vm.updateAction("freehand")
                }
              }
            },
            [_c("BIconPencil")],
            1
          ),
          _c(
            "V3Button",
            {
              attrs: { active: _vm.action === "ellipse" },
              on: {
                click: function($event) {
                  return _vm.updateAction("ellipse")
                }
              }
            },
            [_c("IconEllipse")],
            1
          ),
          _c(
            "V3Button",
            {
              attrs: { active: _vm.action === "rect" },
              on: {
                click: function($event) {
                  return _vm.updateAction("rect")
                }
              }
            },
            [_c("BIconSquare")],
            1
          ),
          _c(
            "V3Button",
            {
              attrs: { active: _vm.action === "line" },
              on: {
                click: function($event) {
                  return _vm.updateAction("line")
                }
              }
            },
            [_c("IconLine")],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "generic-container" },
        [
          _c(
            "V3Button",
            {
              attrs: { active: _vm.action === "drawing-eraser" },
              on: {
                click: function($event) {
                  return _vm.updateAction("drawing-eraser")
                }
              }
            },
            [_c("IconEraser"), _vm._v(" Drawings ")],
            1
          ),
          _c(
            "V3Button",
            {
              attrs: { active: _vm.action === "icon-eraser" },
              on: {
                click: function($event) {
                  return _vm.updateAction("icon-eraser")
                }
              }
            },
            [_c("IconEraser"), _vm._v(" Icons ")],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "dc-container" },
        [
          _vm._l(_vm.widths, function(width, widthIdx) {
            return _vm._l(_vm.colors, function(color, colorIdx) {
              return _c(
                "V3Button",
                {
                  key: color + "-" + width,
                  attrs: {
                    active:
                      _vm.setting.color === colorIdx &&
                      _vm.setting.width === widthIdx
                  },
                  on: {
                    click: function($event) {
                      return _vm.updateSetting({
                        color: colorIdx,
                        width: widthIdx
                      })
                    }
                  }
                },
                [
                  _c("span", {
                    staticClass: "indicator",
                    class: [
                      "color-" +
                        colorIdx +
                        " opacity-" +
                        _vm.setting.opacity +
                        " width-" +
                        widthIdx,
                      {
                        active:
                          _vm.setting.color === colorIdx &&
                          _vm.setting.width === widthIdx
                      }
                    ]
                  }),
                  _c("span", {
                    staticClass: "line",
                    class: [
                      "color-" +
                        colorIdx +
                        " opacity-" +
                        _vm.setting.opacity +
                        " width-" +
                        widthIdx,
                      {
                        active:
                          _vm.setting.color === colorIdx &&
                          _vm.setting.width === widthIdx
                      }
                    ]
                  })
                ]
              )
            })
          })
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "generic-container" },
        [
          _c(
            "V3Button",
            { on: { click: _vm.addSmoke } },
            [_c("IconEllipse"), _vm._v(" Smoke ")],
            1
          ),
          _c("V3Button", { on: { click: _vm.addSpike } }, [
            _c("img", {
              attrs: {
                src: "/images/spike/spike-minimap2.png",
                width: "18",
                height: "18"
              }
            }),
            _vm._v(" Spike ")
          ]),
          _c(
            "V3Button",
            { on: { click: _vm.downloadImage } },
            [_c("BIconDownload"), _vm._v(" Save Image ")],
            1
          )
        ],
        1
      ),
      _vm._v(" DELETE "),
      _c(
        "div",
        { staticClass: "generic-container" },
        [
          _c(
            "V3Button",
            { on: { click: _vm.clearAgents } },
            [_c("BIconPersonFill"), _vm._v(" Agents ")],
            1
          ),
          _c(
            "V3Button",
            { on: { click: _vm.clearUtils } },
            [_c("BIconLightningFill"), _vm._v(" Utils ")],
            1
          ),
          _c(
            "V3Button",
            { on: { click: _vm.clearDrawings } },
            [_c("BIconPencilFill"), _vm._v(" Drawings ")],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "generic-container" },
        [
          _c(
            "V3Button",
            { on: { click: _vm.clearSmokes } },
            [_c("IconEllipse"), _vm._v(" Smokes ")],
            1
          ),
          _c("V3Button", { on: { click: _vm.clearSpikes } }, [
            _c("img", {
              attrs: {
                src: "/images/spike/spike-minimap2.png",
                width: "18",
                height: "18"
              }
            }),
            _vm._v(" Spikes ")
          ]),
          _c(
            "V3Button",
            { on: { click: _vm.clearAll } },
            [_c("IconClear"), _vm._v(" Clear All ")],
            1
          )
        ],
        1
      ),
      _c("span", [_vm._v("AGENTS")]),
      _c(
        "div",
        { staticClass: "agent-toggle" },
        [
          _c(
            "V3Button",
            {
              attrs: { active: !_vm.allAgents },
              on: {
                click: function($event) {
                  return _vm.toggleAllAgents(false)
                }
              }
            },
            [_vm._v(" Only In Match ")]
          ),
          _c(
            "V3Button",
            {
              attrs: { active: _vm.allAgents },
              on: {
                click: function($event) {
                  return _vm.toggleAllAgents(true)
                }
              }
            },
            [_vm._v(" All Agents ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "agent-toggle" },
        [
          _c(
            "V3Button",
            {
              class: { atk: _vm.iconSide === "atk" },
              attrs: { active: _vm.iconSide === "atk" },
              on: {
                click: function($event) {
                  return _vm.toggleIconSide("atk")
                }
              }
            },
            [_vm._v(" ATK ")]
          ),
          _c(
            "V3Button",
            {
              class: { def: _vm.iconSide === "def" },
              attrs: { active: _vm.iconSide === "def" },
              on: {
                click: function($event) {
                  return _vm.toggleIconSide("def")
                }
              }
            },
            [_vm._v(" DEF ")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "agent-list" },
        [
          _vm._l(_vm.agentList, function(agent, idx) {
            return [
              _c(
                "div",
                { key: agent.name + idx, staticClass: "agent-row" },
                [
                  _c(
                    "V3Button",
                    {
                      on: {
                        click: function(e) {
                          return _vm.addIcon(e, agent, "agent", agent.name)
                        }
                      }
                    },
                    [
                      _c("img", {
                        staticClass: "agent-icon",
                        class: [_vm.iconSide],
                        attrs: { src: agent.icon, alt: agent.name }
                      })
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ability-row" },
                    [
                      _vm._l(Object.values(agent.abilities), function(util) {
                        return [
                          _c(
                            "V3Button",
                            {
                              key: util.name + agent.name,
                              on: {
                                click: function(e) {
                                  return _vm.addIcon(
                                    e,
                                    util,
                                    "util",
                                    agent.name
                                  )
                                }
                              }
                            },
                            [
                              _c("img", {
                                staticClass: "util-icon",
                                class: [_vm.iconSide],
                                attrs: { src: util.icon, alt: util.name }
                              })
                            ]
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ]),
    _c(
      "svg",
      {
        attrs: {
          viewBox: "0 0 1024 1024",
          preserveAspectRatio: "xMidYMid meet",
          xmlns: "http://www.w3.org/2000/svg",
          "xmlns:svg": "http://www.w3.org/2000/svg",
          id: "board"
        },
        on: {
          mousedown: function($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "left", 37, $event.key, [
                "Left",
                "ArrowLeft"
              ])
            ) {
              return null
            }
            if ("button" in $event && $event.button !== 0) {
              return null
            }
            return _vm.handleActionMouseDown.apply(null, arguments)
          },
          mouseup: _vm.handleActionMouseUp,
          mousemove: _vm.handleActionMouseMove,
          mouseleave: _vm.handleActionMouseUp,
          contextmenu: function($event) {
            $event.preventDefault()
          }
        }
      },
      [
        _c(
          "g",
          {
            attrs: {
              transform: "rotate(" + -_vm.data.map.rotate_deg + " 512 512)"
            }
          },
          [
            _c("g", { staticClass: "background" }, [
              _c("circle", { attrs: { r: "1e10", fill: "#191822" } }),
              _c("image", {
                attrs: {
                  x: "0",
                  y: "0",
                  width: "1024",
                  height: "1024",
                  href: _vm.data.map.display_icon_url
                }
              }),
              _c("rect", {
                attrs: {
                  x: "0",
                  y: "0",
                  width: "1024",
                  height: "1024",
                  fill: "#191822",
                  opacity: "0.8"
                }
              })
            ])
          ]
        ),
        this.others.length !== 0
          ? _vm._l(this.others, function(a, index) {
              return _c(
                "g",
                {
                  key: index + a.type,
                  staticStyle: { cursor: "pointer" },
                  on: {
                    mousedown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "left", 37, $event.key, [
                          "Left",
                          "ArrowLeft"
                        ])
                      ) {
                        return null
                      }
                      if ("button" in $event && $event.button !== 0) {
                        return null
                      }
                      return function(e) {
                        return _vm.handleOtherMouseDown(e, index)
                      }.apply(null, arguments)
                    },
                    mouseover: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "left", 37, $event.key, [
                          "Left",
                          "ArrowLeft"
                        ])
                      ) {
                        return null
                      }
                      if ("button" in $event && $event.button !== 0) {
                        return null
                      }
                      return _vm.handleIconMouseOver("other", index)
                    }
                  }
                },
                [
                  a.type === "spike"
                    ? _c("image", {
                        attrs: {
                          x: a.x,
                          y: a.y,
                          href: _vm._f("resolveUrl")(
                            "/images/spike/spike-minimap2.png"
                          ),
                          width: "28",
                          height: "28"
                        }
                      })
                    : a.type === "smoke"
                    ? _c("circle", {
                        attrs: {
                          cx: a.x,
                          cy: a.y,
                          r: "30",
                          fill: _vm.smokeColor(a.role),
                          stroke: "rgba(200, 200, 200, 0.75)"
                        },
                        on: {
                          contextmenu: function(e) {
                            return _vm.handleSmokeRightClick(e, index)
                          }
                        }
                      })
                    : _vm._e()
                ]
              )
            })
          : _vm._e(),
        this.icons.length !== 0
          ? _vm._l(this.icons, function(a, index) {
              return _c(
                "g",
                {
                  key: index + a.type + "icon",
                  staticStyle: { cursor: "pointer" },
                  on: {
                    mousedown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "left", 37, $event.key, [
                          "Left",
                          "ArrowLeft"
                        ])
                      ) {
                        return null
                      }
                      if ("button" in $event && $event.button !== 0) {
                        return null
                      }
                      return function(e) {
                        return _vm.handleIconMouseDown(e, index)
                      }.apply(null, arguments)
                    },
                    mouseover: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "left", 37, $event.key, [
                          "Left",
                          "ArrowLeft"
                        ])
                      ) {
                        return null
                      }
                      if ("button" in $event && $event.button !== 0) {
                        return null
                      }
                      return _vm.handleIconMouseOver("icon", index)
                    },
                    contextmenu: function(e) {
                      return _vm.handleIconRightClick(e, index)
                    }
                  }
                },
                [
                  _c("defs", [
                    _c("clipPath", { attrs: { id: "icon" + a.type + index } }, [
                      _c("circle", {
                        attrs: { cx: a.x + 28 / 2, cy: a.y + 28 / 2, r: "14" }
                      })
                    ])
                  ]),
                  _c("circle", {
                    attrs: {
                      cx: a.x + 28 / 2,
                      cy: a.y + 28 / 2,
                      fill: _vm.roleColor(a.role),
                      r: "16"
                    }
                  }),
                  _c("image", {
                    attrs: {
                      "clip-path": "url(#" + "icon" + a.type + index + ")",
                      x: a.x,
                      y: a.y,
                      href: a.icon,
                      height: 28,
                      width: 28
                    }
                  })
                ]
              )
            })
          : _vm._e(),
        this.drawings.length !== 0
          ? _c(
              "g",
              [
                _vm._l(this.drawings, function(dr, index) {
                  return [
                    dr.type === "rect"
                      ? _c("rect", {
                          key: dr.type + index,
                          attrs: {
                            x: dr.x,
                            y: dr.y,
                            width: dr.width,
                            height: dr.height,
                            stroke: dr.color,
                            "stroke-width": dr.stroke,
                            fill: "none"
                          },
                          on: {
                            mouseover: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "left", 37, $event.key, [
                                  "Left",
                                  "ArrowLeft"
                                ])
                              ) {
                                return null
                              }
                              if ("button" in $event && $event.button !== 0) {
                                return null
                              }
                              return _vm.handleMouseOverDrawing(index)
                            }
                          }
                        })
                      : _vm._e(),
                    dr.type === "line"
                      ? _c("line", {
                          key: dr.type + index,
                          attrs: {
                            x1: dr.initX,
                            y1: dr.initY,
                            x2: dr.currX,
                            y2: dr.currY,
                            stroke: dr.color,
                            "stroke-width": dr.stroke
                          },
                          on: {
                            mouseover: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "left", 37, $event.key, [
                                  "Left",
                                  "ArrowLeft"
                                ])
                              ) {
                                return null
                              }
                              if ("button" in $event && $event.button !== 0) {
                                return null
                              }
                              return _vm.handleMouseOverDrawing(index)
                            }
                          }
                        })
                      : _vm._e(),
                    dr.type === "ellipse"
                      ? _c("ellipse", {
                          key: dr.type + index,
                          attrs: {
                            cx: dr.cx,
                            cy: dr.cy,
                            rx: dr.rx,
                            ry: dr.ry,
                            stroke: dr.color,
                            "stroke-width": dr.stroke,
                            fill: "none"
                          },
                          on: {
                            mouseover: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "left", 37, $event.key, [
                                  "Left",
                                  "ArrowLeft"
                                ])
                              ) {
                                return null
                              }
                              if ("button" in $event && $event.button !== 0) {
                                return null
                              }
                              return _vm.handleMouseOverDrawing(index)
                            }
                          }
                        })
                      : _vm._e(),
                    dr.type === "freehand"
                      ? _c("path", {
                          key: dr.type + index,
                          attrs: {
                            d: dr.path,
                            stroke: dr.color,
                            "stroke-width": dr.stroke,
                            fill: "none"
                          },
                          on: {
                            mouseover: function($event) {
                              if (
                                !$event.type.indexOf("key") &&
                                _vm._k($event.keyCode, "left", 37, $event.key, [
                                  "Left",
                                  "ArrowLeft"
                                ])
                              ) {
                                return null
                              }
                              if ("button" in $event && $event.button !== 0) {
                                return null
                              }
                              return _vm.handleMouseOverDrawing(index)
                            }
                          }
                        })
                      : _vm._e()
                  ]
                })
              ],
              2
            )
          : _vm._e()
      ],
      2
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tooltipmain" }, [
      _vm._v(" ? "),
      _c("span", { staticClass: "tooltiptext" }, [
        _vm._v(
          " To add Agents and Utility Icons to the board simply click on them. Right clicking an Icon & Smoke will change their role. The Icon Eraser also erases Smoke and Spike. Holding CTRL when left clicking on an Agent, Utility, Smoke, and Spike on the board will delete them. In the Agent UI section, holding CTRL when left clicking an icon will clear it from the board; while holding ALT will clear all agent and utility icons of said clicked agent (both CTRL and ALT will clear icons regardless of side). "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }