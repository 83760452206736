var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3Panel",
    {
      staticClass: "au-container",
      attrs: {
        expandable: "",
        expanded: _vm.expanded,
        title: "Ability usages",
        variant: "secondary"
      },
      on: {
        "update:expanded": function($event) {
          _vm.expanded = $event
        }
      }
    },
    [
      _c(
        "V3Container",
        { staticClass: "au-filter", attrs: { visible: _vm.expanded } },
        [
          _vm._l(_vm.teams, function(team) {
            return [
              _c(
                "div",
                { key: team.id + "-team", staticClass: "au-team-container" },
                [
                  _c(
                    "V3Button",
                    {
                      staticClass: "au-team",
                      attrs: { active: _vm.isTeamActive(team) },
                      on: {
                        click: function($event) {
                          return _vm.toggleTeam(team)
                        }
                      }
                    },
                    [
                      _c("V3TeamLogo", {
                        staticClass: "au-team-logo",
                        attrs: {
                          grayscale: !_vm.isTeamActive(team),
                          src: team.image
                        }
                      }),
                      _c("div", { staticClass: "au-team-name" }, [
                        _vm._v(_vm._s(team.name))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "au-matchPlayer-list" },
                    [
                      _vm._l(_vm.teamMatchPlayers(team), function(matchPlayer) {
                        return [
                          _c(
                            "V3MapFilter",
                            {
                              key: matchPlayer.id,
                              staticClass: "au-matchPlayer-container",
                              attrs: {
                                active: _vm.isMatchPlayerActive(matchPlayer),
                                icon:
                                  _vm.data.agents[matchPlayer.agent_id]
                                    .display_icon_small_url,
                                label:
                                  _vm.data.players[matchPlayer.player_id].name
                              },
                              on: {
                                click: function($event) {
                                  return _vm.toggleMatchPlayer(matchPlayer)
                                }
                              }
                            },
                            [
                              _vm._l(
                                _vm.agentAbilities(matchPlayer.agent_id),
                                function(ability) {
                                  return [
                                    _c(
                                      "V3Button",
                                      {
                                        key: ability.slot,
                                        staticClass: "au-ability",
                                        style: { order: ability.order },
                                        attrs: {
                                          active: _vm.isAbilityActive(
                                            matchPlayer,
                                            ability
                                          )
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.toggleAbility(
                                              matchPlayer,
                                              ability
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("V3Image", {
                                          staticClass: "au-ability-icon",
                                          attrs: {
                                            alt: ability.displayName,
                                            gray: !_vm.isAbilityActive(
                                              matchPlayer,
                                              ability
                                            ),
                                            src: ability.displayIcon,
                                            title: ability.displayName
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ]
                                }
                              )
                            ],
                            2
                          )
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }