var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3Panel",
    {
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "div",
                { staticClass: "trs-team" },
                [
                  _c("TeamLogo", {
                    staticClass: "trs-team-logo",
                    attrs: { src: _vm.team.logo_url }
                  }),
                  _c("span", { staticClass: "trs-team-name" }, [
                    _vm._v(_vm._s(_vm.team.name))
                  ]),
                  _vm.data.teams[_vm.team.id].abbr
                    ? _c("span", { staticClass: "trs-team-abbr" }, [
                        _vm._v(" - " + _vm._s(_vm.data.teams[_vm.team.id].abbr))
                      ])
                    : _vm._e()
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "div",
        { staticClass: "trs-table" },
        [
          _c("div", { staticClass: "trs-table-header" }),
          _c("div", { staticClass: "trs-col-head trs-col-player" }, [
            _vm._v("PLR")
          ]),
          _c("div", { staticClass: "trs-col-head trs-col-ult" }, [
            _vm._v("ULT")
          ]),
          _c("div", { staticClass: "trs-col-head trs-col-armor" }, [
            _vm._v("A")
          ]),
          _c("div", { staticClass: "trs-col-head trs-col-weapon" }, [
            _vm._v("W")
          ]),
          _c("div", { staticClass: "trs-col-head trs-col-load" }, [
            _vm._v("LOAD"),
            _c("br"),
            _vm._v("(S/R)")
          ]),
          _c("div", { staticClass: "trs-col-head trs-col-kda" }, [
            _vm._v("KDA")
          ]),
          _vm._l(_vm.sortedPlayers, function(player, idx) {
            return [
              _c("div", {
                key: "bg" + idx,
                staticClass: "trs-table-row",
                class: "r" + idx
              }),
              _c(
                "div",
                {
                  key: "player" + idx,
                  staticClass: "trs-cell trs-cell-player",
                  class: "r" + idx
                },
                [
                  _c("img", {
                    staticClass: "trs-cell-player-icon",
                    attrs: { src: player.agent_icon_url }
                  }),
                  _c("span", { staticClass: "trs-cell-player-name" }, [
                    _vm._v(_vm._s(player.name))
                  ])
                ]
              ),
              _c(
                "div",
                {
                  key: "ult" + idx,
                  staticClass: "trs-cell trs-cell-ult",
                  class: "r" + idx
                },
                [
                  player.ultimates && player.ultimates.count !== null
                    ? _c("div", [
                        player.ultimates.count !== null &&
                        player.ultimates.count === player.ultimates.max
                          ? _c("img", {
                              staticClass: "trs-cell-ult-icon",
                              attrs: {
                                src: _vm.ultimateImageUrl(player.agent_name),
                                alt: "READY"
                              }
                            })
                          : _c("span", { staticClass: "trs-cell-ult-label" }, [
                              _vm._v(
                                _vm._s(player.ultimates.count) +
                                  "/" +
                                  _vm._s(player.ultimates.max)
                              )
                            ])
                      ])
                    : _c("span", { staticClass: "trs-cell-ult-label" }, [
                        _vm._v("N/A")
                      ])
                ]
              ),
              _c(
                "div",
                {
                  key: "armor" + idx,
                  staticClass: "trs-cell trs-cell-armor",
                  class: "r" + idx
                },
                [
                  player.armor_url
                    ? _c("img", {
                        staticClass: "trs-cell-armor-icon",
                        attrs: { src: player.armor_url, alt: player.armor_name }
                      })
                    : _c("span", { staticClass: "trs-cell-armor-label" }, [
                        _vm._v("-")
                      ])
                ]
              ),
              _c(
                "div",
                {
                  key: "weapon" + idx,
                  staticClass: "trs-cell trs-cell-weapon",
                  class: "r" + idx
                },
                [
                  player.weapon_name
                    ? _c("img", {
                        staticClass: "trs-cell-weapon-icon",
                        attrs: {
                          src:
                            _vm.weaponsByName[player.weapon_name]
                              .kill_stream_icon_url,
                          alt: _vm.weaponsByName[player.weapon_name].name
                        }
                      })
                    : _c("span", { staticClass: "trs-cell-weapon-label" }, [
                        _vm._v("-")
                      ])
                ]
              ),
              _c(
                "div",
                {
                  key: "load" + idx,
                  staticClass: "trs-cell trs-cell-load",
                  class: "r" + idx
                },
                [
                  _c("div", { staticClass: "trs-cell-load-label" }, [
                    _vm._v(_vm._s(_vm._f("to_credits")(player.loadout)))
                  ]),
                  _c("div", { staticClass: "trs-cell-load-label2" }, [
                    _vm._v(
                      _vm._s(_vm._f("to_credits")(player.spent)) +
                        "/" +
                        _vm._s(_vm._f("to_credits")(player.remaining))
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  key: "kda" + idx,
                  staticClass: "trs-cell trs-cell-kda",
                  class: "r" + idx
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(player.kills) +
                      "/" +
                      _vm._s(player.deaths) +
                      "/" +
                      _vm._s(player.assists) +
                      " "
                  )
                ]
              )
            ]
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }