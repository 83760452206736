var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-form-checkbox-group", {
    staticClass: "zone__items",
    attrs: {
      checked: _vm.checked,
      options: _vm.options,
      name: "zones",
      switches: "",
      size: "lg"
    },
    on: { input: _vm.update }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }