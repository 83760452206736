var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("V3Panel", [
    _c(
      "div",
      { staticClass: "xvy-container" },
      [
        _c("div", { staticClass: "selected-team" }, [
          _vm._v(
            " Team: " + _vm._s(this.data.teams[this.selected.team].name) + " - "
          ),
          _c(
            "span",
            { staticClass: "role", class: [this.state.filters.round.teamRole] },
            [_vm._v(_vm._s(this.state.filters.round.teamRole))]
          )
        ]),
        _vm._l(this.rounds, function(round, idx) {
          return [
            _c("div", { key: idx }, [
              _c("div", [
                _c("div", { staticClass: "header" }, [
                  _vm._v(" Round " + _vm._s(round.number) + " - "),
                  _c(
                    "span",
                    { staticClass: "outcome", class: [round.winner.role] },
                    [
                      _c(_vm.icon(round.outcome), { tag: "component" }),
                      _vm._v(" " + _vm._s(round.plant_site) + " ")
                    ],
                    1
                  )
                ])
              ]),
              _c(
                "div",
                { staticClass: "round" },
                [
                  _vm._l(round.round_player_count, function(state, id) {
                    return [
                      _c(
                        "div",
                        {
                          key: id,
                          staticClass: "state",
                          class: state.status,
                          on: {
                            click: function($event) {
                              return _vm.$emit("select", state)
                            }
                          }
                        },
                        [
                          state.planted
                            ? _c("img", {
                                attrs: {
                                  src: "/images/spike/spike-minimap.png"
                                }
                              })
                            : _vm._e(),
                          _vm._v(" " + _vm._s(state.xvy) + " ")
                        ]
                      )
                    ]
                  })
                ],
                2
              )
            ])
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }