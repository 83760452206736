var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1.0em",
        height: "1.0em",
        viewBox: "0 0 24 24",
        stroke: "currentColor",
        fill: "currentColor"
      }
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "m15 22h-11c-1.1 0-2-.9-2-2v-11c0-.6.4-1 1-1s1 .4 1 1v11h11c.6 0 1 .4 1 1s-.4 1-1 1z"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "m20 18h-12c-1.1 0-2-.9-2-2v-12c0-1.1.9-2 2-2h12c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2zm-12-14v12h12v-12z"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "m17.7 8.7c.4-.4.4-1 0-1.4s-1-.4-1.4 0zm-4.7 3.3-.7.7c.4.4 1 .4 1.4 0zm-1.3-2.7c-.4-.4-1-.4-1.4 0s-.4 1 0 1.4zm4.6-2-4 4 1.4 1.4 4-4zm-2.6 4-2-2-1.4 1.4 2 2z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }