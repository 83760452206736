var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 512 512",
        fill: "currentColor"
      }
    },
    [
      _c("g", [
        _c("path", {
          attrs: {
            d:
              "M96,427.3c1.4,0,2.9-0.3,4.2-0.8L232.6,376c1.6-0.6,3-1.5,4.2-2.7l233.1-233c27.3-27.3,27.3-71.7,0-99s-71.7-27.3-99,0\n\t\tL137.8,274.4c-1.2,1.2-2.1,2.6-2.7,4.2L84.8,411c-1.7,4.4-0.6,9.4,2.7,12.8C89.8,426,92.8,427.3,96,427.3L96,427.3z M387.9,58.2\n\t\tc18-18,47.1-18,65.1,0s18,47.1,0,65.1l-26,26.1l-65.1-65.1L387.9,58.2z M156.6,289.5L345,101.2l65,65.1L221.8,354.6l-105,40\n\t\tL156.6,289.5z"
          }
        }),
        _c("path", {
          attrs: {
            d:
              "M480,467.3H32c-6.6,0-12,5.4-12,12s5.4,12,12,12h448c6.6,0,12-5.4,12-12S486.6,467.3,480,467.3z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }