var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3ScrollableContainer",
    { staticClass: "lsb-container", attrs: { vertical: "" } },
    [
      _c(
        "V3Panel",
        {
          attrs: {
            expandable: _vm.vodPlayer !== "vod" || _vm.mapMode !== "replay2d",
            expanded: _vm.viewsExpanded,
            icon: _vm.$options.components.IconCalendarView,
            title: "views"
          },
          on: {
            "update:expanded": function($event) {
              _vm.viewsExpanded = $event
            }
          }
        },
        [
          _c("MapViewOptions", {
            attrs: {
              expanded: _vm.viewsExpanded,
              "vod-platform": _vm.vodPlatform,
              "has-playback-positions": _vm.hasPlaybackPositions,
              "has-minimap-vods": _vm.hasMinimapVods,
              "has-broadcast-vod": _vm.hasBroadcastVod
            }
          })
        ],
        1
      ),
      _vm.drawingConfig && _vm.drawings && _vm.mapSettings.drawingEnabled
        ? _c(
            "V3Panel",
            {
              staticClass: "lsb-drawing",
              attrs: {
                expandable: "",
                expanded: _vm.state.drawingEnabled,
                icon: _vm.$options.components.IconPaintBrushAlt,
                title: "Draw"
              },
              on: {
                "update:expanded": function($event) {
                  return _vm.$set(_vm.state, "drawingEnabled", $event)
                }
              }
            },
            [
              _c(
                "V3Container",
                { attrs: { visible: _vm.state.drawingEnabled } },
                [
                  _c("DrawingConfig", {
                    on: { clear: _vm.clearDrawings, undo: _vm.undoLastDrawing }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.multimatch
        ? _c(
            "V3Panel",
            {
              staticClass: "lsb-matches",
              attrs: {
                expandable: "",
                expanded: _vm.matchesExpanded,
                icon: _vm.$options.components.IconSelect,
                title: "Matches"
              },
              on: {
                "update:expanded": function($event) {
                  _vm.matchesExpanded = $event
                }
              }
            },
            [
              _c(
                "V3Container",
                { attrs: { visible: _vm.matchesExpanded } },
                [_c("MapMatchFilter")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      !_vm.multimatch && _vm.mapSettings.bookmarksEnabled
        ? _c(
            "V3Panel",
            {
              staticClass: "lsb-bookmarks",
              attrs: {
                expandable: "",
                expanded: _vm.bookmarksExpanded,
                icon: _vm.$options.components.IconBookmark,
                title: "Bookmarks"
              },
              on: {
                "update:expanded": function($event) {
                  _vm.bookmarksExpanded = $event
                }
              }
            },
            [
              _c(
                "V3Container",
                { attrs: { visible: _vm.bookmarksExpanded } },
                [
                  _vm._l(_vm.data.matches, function(match) {
                    return [
                      _c("MapBookmarks", {
                        key: match.id,
                        attrs: { bookmarks: _vm.bookmarks, match: match },
                        on: {
                          select: function($event) {
                            return _vm.$emit("selectBookmark", $event)
                          }
                        }
                      })
                    ]
                  })
                ],
                2
              ),
              _vm.singleMatch
                ? [
                    _c(
                      "V3Container",
                      { attrs: { visible: !_vm.creatingBookmark } },
                      [
                        _c(
                          "V3Button",
                          {
                            staticClass: "lsb-add-bookmark",
                            attrs: { disabled: _vm.savingBookmark },
                            on: { click: _vm.onAddBookmarkClick }
                          },
                          [
                            _vm.savingBookmark
                              ? [_c("LoadingCell")]
                              : [
                                  _c("BIconJournalPlus"),
                                  _c("div", [_vm._v("Add bookmark")])
                                ]
                          ],
                          2
                        )
                      ],
                      1
                    ),
                    _c(
                      "V3Container",
                      { attrs: { visible: _vm.creatingBookmark } },
                      [
                        _c("CreateBookmarkForm", {
                          ref: "createBookmarkForm",
                          attrs: { "note-tags": _vm.noteTags },
                          on: {
                            submit: _vm.createBookmark,
                            cancel: function($event) {
                              _vm.creatingBookmark = false
                            }
                          }
                        })
                      ],
                      1
                    )
                  ]
                : _vm._e()
            ],
            2
          )
        : _vm._e(),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }