var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-container", class: { expanded: _vm.expanded } },
    [
      _c(
        "summary",
        {
          staticClass: "col-form-label clearfix",
          class: _vm.computedStatus != null && "has-status",
          on: {
            click: function($event) {
              _vm.expanded = !_vm.expanded
            }
          }
        },
        [
          !_vm.expanded ? _c("BIconCaretDownFill") : _vm._e(),
          _vm.expanded ? _c("BIconCaretUpFill") : _vm._e(),
          _vm._t("label", function() {
            return [_vm._v(" " + _vm._s(_vm.label) + " ")]
          }),
          _vm.computedStatus != null
            ? _c("span", [_vm._v("(" + _vm._s(_vm.computedStatus) + ")")])
            : _vm._e()
        ],
        2
      ),
      _c(
        "BCollapse",
        {
          model: {
            value: _vm.expanded,
            callback: function($$v) {
              _vm.expanded = $$v
            },
            expression: "expanded"
          }
        },
        [_vm._t("default")],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }