var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    _vm._l(_vm.currentEvents, function(event) {
      return _c(_vm.eventComponent(_vm.variant, event), {
        key:
          _vm.currentTime != null
            ? "" + event.track_id + event.gid + event.round_id
            : event.id,
        tag: "component",
        attrs: {
          data: _vm.data,
          event: event,
          faded: _vm.fading && !_vm.highlightedGids[event.gid],
          hidden: _vm.hidden(event),
          highlight: _vm.highlightEvent === event.id
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }