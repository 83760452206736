var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-modal" }, [
    _c("div", { staticClass: "map-content" }, [
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c(
            "V3BtnGroup",
            [
              _c(
                "V3Button",
                { attrs: { active: true }, on: { click: _vm.addPlayerSearch } },
                [_vm._v("Add Player")]
              )
            ],
            1
          ),
          _c(
            "div",
            [
              _vm._l(this.playerSearch, function(plr, idx) {
                return [
                  _c(
                    "div",
                    { key: idx, staticClass: "player-container" },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: plr.player,
                              expression: "plr.player"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                plr,
                                "player",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.agentPlayerList, function(player) {
                          return _c(
                            "option",
                            {
                              key: player.id + player.agent,
                              domProps: { value: player }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(player.agent) +
                                  " - " +
                                  _vm._s(player.name) +
                                  " "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      plr
                        ? _c(
                            "span",
                            { staticClass: "role", class: [plr.player.side] },
                            [_vm._v(_vm._s(plr.player.side))]
                          )
                        : _vm._e(),
                      _c(
                        "V3Button",
                        {
                          staticClass: "delete-button",
                          on: {
                            click: function($event) {
                              return _vm.deletePlayer(idx)
                            }
                          }
                        },
                        [_vm._v("DELETE")]
                      )
                    ],
                    1
                  )
                ]
              })
            ],
            2
          ),
          _c(
            "div",
            { staticClass: "settings-button-group" },
            [
              _c(
                "V3Button",
                {
                  staticClass: "normal-button",
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "V3Button",
                {
                  staticClass: "save-button",
                  attrs: { disabled: !this.canSave },
                  on: { click: _vm.saveSnapshot }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "svg",
        {
          attrs: {
            width: "512",
            height: "512",
            viewBox: "0 0 1024 1024",
            id: "scenario-minimap"
          },
          on: {
            mousedown: _vm.handleMouseDown,
            mouseup: _vm.handleMouseUp,
            mousemove: _vm.handleMouseMove,
            mouseleave: _vm.handleMouseUp
          }
        },
        [
          _c("image", {
            attrs: {
              x: "0",
              y: "0",
              width: "1024",
              height: "1024",
              href: _vm.data.map.display_icon_url,
              transform: "rotate(" + -_vm.data.map.rotate_deg + " 512 512)"
            }
          }),
          this.elements.length !== 0
            ? _c("g", [
                _c("rect", {
                  attrs: {
                    x: this.elements[0].x,
                    y: this.elements[0].y,
                    width: this.elements[0].width,
                    height: this.elements[0].height,
                    stroke: "red",
                    "stroke-width": "4",
                    fill: "none"
                  }
                })
              ])
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }