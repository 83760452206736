var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 48 48"
      }
    },
    [
      _c("g", { attrs: { fill: "none" } }, [
        _c(
          "g",
          { attrs: { stroke: "currentColor", "clip-path": "url(#svgIDa)" } },
          [
            _c("path", {
              attrs: {
                "stroke-linejoin": "round",
                "stroke-width": "4.302",
                d:
                  "M44.782 24.17L31.918 7.1L14.135 20.5L27.5 37l3.356-2.336L44.782 24.17Z"
              }
            }),
            _c("path", {
              attrs: {
                "stroke-linejoin": "round",
                "stroke-width": "4.302",
                d:
                  "m27.5 37l-3.839 3.075l-10.563-.001l-2.6-3.45l-6.433-8.536L14.5 20.225"
              }
            }),
            _c("path", {
              attrs: {
                "stroke-linecap": "round",
                "stroke-width": "4.5",
                d: "M13.206 40.072h31.36"
              }
            })
          ]
        ),
        _c("defs", [
          _c("clipPath", { attrs: { id: "svgIDa" } }, [
            _c("path", { attrs: { fill: "currentColor", d: "M0 0h48v48H0z" } })
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }