var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3Panel",
    {
      staticClass: "mb-container",
      attrs: { expandable: "", expanded: _vm.expanded, variant: "secondary" },
      on: {
        "update:expanded": function($event) {
          _vm.expanded = $event
        }
      },
      scopedSlots: _vm._u([
        {
          key: "header-content",
          fn: function(ref) {
            var expanded = ref.expanded
            return [
              _c(
                "div",
                {
                  staticClass: "mb-header-container",
                  class: { expanded: expanded }
                },
                [
                  _vm.match.event
                    ? _c("div", { staticClass: "mb-header-event" }, [
                        _vm._v(" " + _vm._s(_vm.match.event.name) + " ")
                      ])
                    : _c("div", { staticClass: "mb-header-date" }, [
                        _c(
                          "time",
                          {
                            attrs: {
                              datetime: _vm._f("isoString")(_vm.match.created)
                            }
                          },
                          [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("localDate")(_vm.match.created))
                              )
                            ]),
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm._f("localTime")(_vm.match.created))
                              )
                            ])
                          ]
                        )
                      ]),
                  _vm.winnerTeam.image
                    ? _c("V3Image", {
                        staticClass: "mb-header-team-logo",
                        attrs: {
                          gray: !expanded,
                          outline: expanded,
                          src: _vm.winnerTeam.image
                        }
                      })
                    : _c("div", { staticClass: "mb-header-team-name" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.winnerTeam.abbr || _vm.winnerTeam.name) +
                            " "
                        )
                      ]),
                  _c("div", { staticClass: "mb-header-score" }, [
                    _c(
                      "span",
                      {
                        staticClass: "mb-header-score-winner",
                        class: _vm.winnerGrid
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.winnerTeam ? _vm.winnerTeam.score : "-"
                            ) +
                            " "
                        )
                      ]
                    ),
                    _c("span", { staticClass: "mb-header-score-sep" }, [
                      _vm._v("/")
                    ]),
                    _c(
                      "span",
                      {
                        staticClass: "mb-header-score-loser",
                        class: _vm.loserGrid
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.loserTeam ? _vm.loserTeam.score : "-") +
                            " "
                        )
                      ]
                    )
                  ]),
                  _vm.loserTeam.image
                    ? _c("V3Image", {
                        staticClass: "mb-header-team-logo",
                        attrs: {
                          gray: !expanded,
                          outline: expanded,
                          src: _vm.loserTeam.image
                        }
                      })
                    : _c("div", { staticClass: "mb-header-team-name" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.loserTeam.abbr || _vm.loserTeam.name) +
                            " "
                        )
                      ])
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "V3Container",
        { attrs: { visible: _vm.expanded } },
        _vm._l(_vm.normalizedBookmarks, function(bookmark) {
          return _c(
            "V3Button",
            {
              key: bookmark.id,
              staticClass: "mbi-container",
              on: {
                click: function($event) {
                  return _vm.$emit("select", bookmark.raw)
                }
              }
            },
            [
              _c(
                "div",
                { staticClass: "mbi-left-section" },
                [
                  _c(
                    "div",
                    { staticClass: "mbi-mark", class: bookmark.teamRole },
                    [_c("IconTriangle")],
                    1
                  ),
                  _c("V3Image", {
                    staticClass: "mbi-team-logo",
                    attrs: { outline: "", src: bookmark.teamLogo }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "mbi-content" }, [
                _c("div", { staticClass: "mbi-content-note" }, [
                  _vm._v(" " + _vm._s(bookmark.text) + " ")
                ])
              ]),
              bookmark.icon
                ? _c(
                    "div",
                    { staticClass: "mbi-icon" },
                    [
                      bookmark.icon.icon
                        ? _c(bookmark.icon.icon, { tag: "component" })
                        : _c("img", {
                            staticClass: "bookmark-icon",
                            attrs: { src: bookmark.icon.asset }
                          })
                    ],
                    1
                  )
                : _vm._e(),
              _c("V3Image", {
                staticClass: "mbi-image",
                attrs: {
                  src:
                    bookmark.preview || "/images/sample-bookmark-screenshot.png"
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }