var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 37.71 56.07",
        width: "38",
        height: "56"
      }
    },
    [
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points: "6.45 0 0 0 18.69 28.03 0 56.07 6.45 56.07 25.14 28.03 6.45 0"
        }
      }),
      _c("polygon", {
        staticClass: "cls-2",
        attrs: {
          points:
            "12.84 0 6.38 0 25.07 28.03 6.38 56.07 12.84 56.07 31.52 28.03 12.84 0"
        }
      }),
      _c("polygon", {
        staticClass: "cls-3",
        attrs: {
          points:
            "19.02 0 12.57 0 31.26 28.03 12.57 56.07 19.02 56.07 37.71 28.03 19.02 0"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }