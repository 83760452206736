var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-form-radio-group", {
    attrs: {
      options: _vm.options,
      name: "positions",
      switches: "",
      size: "lg",
      checked: _vm.value
    },
    on: {
      change: function(val) {
        return _vm.$emit("change", val)
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }