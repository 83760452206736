var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("b-form-checkbox-group", {
    attrs: {
      name: "kills",
      multiple: "",
      stacked: "",
      switches: "",
      checked: _vm.checked,
      options: _vm.options
    },
    on: { input: _vm.update }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }