var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "v3-filter-container" },
    [
      _vm.label || _vm.icon
        ? _c(
            "V3Button",
            _vm._g(
              {
                staticClass: "v3-filter-label-container",
                attrs: { active: _vm.active }
              },
              _vm.$listeners
            ),
            [
              _vm._t("label", function() {
                return [
                  typeof _vm.icon === "string"
                    ? _c(
                        "V3Image",
                        _vm._b(
                          {
                            staticClass: "v3-filter-label-img",
                            attrs: {
                              grayscale: !_vm.active,
                              outline: _vm.active && _vm.outline,
                              src: _vm.icon
                            }
                          },
                          "V3Image",
                          _vm.iconAttrs,
                          false
                        )
                      )
                    : _vm._e(),
                  typeof _vm.icon === "object"
                    ? _c(
                        _vm.icon,
                        _vm._b(
                          {
                            tag: "component",
                            staticClass: "v3-filter-label-icon"
                          },
                          "component",
                          _vm.iconAttrs,
                          false
                        )
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "v3-filter-label" }, [
                    _vm._v(_vm._s(_vm.label))
                  ])
                ]
              })
            ],
            2
          )
        : _vm._e(),
      _c("V3BtnGroup", [_vm._t("default")], 2)
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }