var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mtrs-container" },
    [
      _c(
        "div",
        { staticClass: "mtrs-teams-container" },
        _vm._l(_vm.teams, function(team, index) {
          return _c("div", { key: index, staticClass: "mtrs-team" }, [
            _vm._v(" " + _vm._s(team.abbr || team.name) + " ")
          ])
        }),
        0
      ),
      _vm.data &&
      _vm.data.rounds &&
      _vm.data.roundTeams &&
      _vm.data.teams &&
      _vm.filters &&
      _vm.filters.round
        ? _c("MatchRoundSelector", {
            attrs: {
              rounds: _vm.rounds,
              disabled: _vm.disabled,
              highlighted: _vm.highlighted,
              selected: _vm.selectedRounds,
              errored: _vm.errored,
              notes: _vm.roundNotes
            },
            on: {
              click: _vm.onClick,
              "switch-role": function($event) {
                return _vm.$emit("switch-role")
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "team",
                  fn: function(ref) {
                    var team = ref.team
                    return [
                      _c("div", { staticClass: "team-name" }, [
                        _vm._v(_vm._s(team.abbr || team.name))
                      ])
                    ]
                  }
                }
              ],
              null,
              false,
              3562002547
            )
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mtrs-teams-container" },
        _vm._l(_vm.teams, function(team, index) {
          return _c("div", { key: index, staticClass: "mtrs-team" }, [
            _vm._v(" " + _vm._s(team.abbr || team.name) + " ")
          ])
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }