var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.kdZoneMode.zone
    ? _c(
        "g",
        [
          _vm._l(this.kdData.zones, function(z, idx) {
            return [
              z !== _vm.kdZoneMode.selected
                ? _c("g", { key: idx }, [
                    _c("polygon", {
                      staticStyle: { cursor: "pointer" },
                      attrs: {
                        stroke: "black",
                        fill: _vm.getColor(z),
                        "fill-opacity": z.perc,
                        points: _vm.polyCoords(z.poly),
                        "stroke-width": "3"
                      },
                      on: {
                        click: function($event) {
                          return _vm.setZone(z)
                        }
                      }
                    })
                  ])
                : _vm._e()
            ]
          }),
          _vm.kdZoneMode.selected
            ? _c("g", [
                _c("polygon", {
                  staticStyle: { cursor: "pointer" },
                  attrs: {
                    stroke: "yellow",
                    fill: "none",
                    "pointer-events": "visible",
                    "fill-opacity": _vm.kdZoneMode.selected.perc,
                    points: _vm.polyCoords(_vm.kdZoneMode.selected.poly),
                    "stroke-width": "2"
                  },
                  on: {
                    click: function($event) {
                      return _vm.setZone(_vm.kdZoneMode.selected)
                    }
                  }
                })
              ])
            : _vm._e(),
          _vm.kdZoneMode.selected
            ? [
                _c(
                  "g",
                  {
                    attrs: {
                      transform:
                        "rotate(" + _vm.data.map.rotate_deg + " 512 512)"
                    }
                  },
                  [
                    _c("circle", {
                      attrs: { cx: -20, cy: 900, r: "7", fill: "green" }
                    }),
                    _c(
                      "text",
                      {
                        attrs: {
                          fill: "white",
                          x: "0",
                          y: "870",
                          "font-size": "2rem"
                        }
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(this.data.teams[this.selected.team].name) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "text",
                      {
                        attrs: {
                          fill: "white",
                          x: "0",
                          y: "910",
                          "font-size": "2rem"
                        }
                      },
                      [
                        _vm._v(
                          " Kills: " +
                            _vm._s(_vm.kdZoneMode.selected.kill) +
                            " "
                        )
                      ]
                    ),
                    _c("polygon", {
                      attrs: {
                        transform: "rotate(90 -20, 930)",
                        points: _vm.triangle(-20, 930, 8),
                        fill: "red"
                      }
                    }),
                    _c(
                      "text",
                      {
                        attrs: {
                          fill: "white",
                          x: "0",
                          y: "940",
                          "font-size": "2rem"
                        }
                      },
                      [
                        _vm._v(
                          " Deaths: " +
                            _vm._s(_vm.kdZoneMode.selected.death) +
                            " "
                        )
                      ]
                    ),
                    _c(
                      "text",
                      {
                        attrs: {
                          fill: "white",
                          x: "0",
                          y: "970",
                          "font-size": "2rem"
                        }
                      },
                      [
                        _vm._v(
                          " KD Score: " +
                            _vm._s(_vm.kdZoneMode.selected.score) +
                            " "
                        )
                      ]
                    ),
                    _vm.kdZoneMode.legend
                      ? _c(
                          "g",
                          _vm._l(this.presentPlayers, function(item, index) {
                            return _c(
                              "g",
                              {
                                key: item.id,
                                attrs: {
                                  fill: _vm.legend[item.id],
                                  transform:
                                    "translate(1000, " +
                                    (1014 - (index + 1) * 30) +
                                    ")"
                                }
                              },
                              [
                                _vm._l(item.matchPlayerId, function(
                                  matchPlayerId,
                                  ind
                                ) {
                                  return _c("g", { key: matchPlayerId }, [
                                    _c("image", {
                                      attrs: {
                                        x: -(ind * 30),
                                        y: "0",
                                        width: "26",
                                        height: "26",
                                        href: _vm.playerAgent(matchPlayerId)
                                          .display_icon_small_url
                                      }
                                    })
                                  ])
                                }),
                                _c(
                                  "text",
                                  {
                                    attrs: {
                                      x: "30",
                                      y: "20",
                                      "font-size": "1.5rem"
                                    }
                                  },
                                  [_vm._v(_vm._s(item.name))]
                                )
                              ],
                              2
                            )
                          }),
                          0
                        )
                      : _vm._e()
                  ]
                ),
                _vm._l(_vm.kdZoneMode.selected.list, function(k, idx) {
                  return [
                    k.type === "kill" &&
                    _vm.kdZoneMode.view !== "death" &&
                    (_vm.playerVisible(k.killer) ||
                      _vm.playerVisible(k.victim)) &&
                    _vm.firstFlag(k.first) &&
                    _vm.plantTimingFlag(k.pre_post_plant)
                      ? _c("g", { key: idx }, [
                          _vm.kdZoneMode.opps
                            ? _c("line", {
                                attrs: {
                                  x1: k.killer.location.x * 1024,
                                  y1: k.killer.location.y * 1024,
                                  x2: k.victim.location.x * 1024,
                                  y2: k.victim.location.y * 1024,
                                  stroke: "black",
                                  "stroke-width": "2"
                                }
                              })
                            : _vm._e(),
                          _c("circle", {
                            attrs: {
                              transform:
                                "translate(" +
                                k.killer.location.x * 1024 +
                                ", " +
                                k.killer.location.y * 1024 +
                                ")",
                              cx: k.killer.location.x,
                              cy: k.killer.location.y,
                              r: "7",
                              fill: _vm.useLegend(
                                k.killer.match_player_id,
                                "green"
                              )
                            }
                          }),
                          _vm.kdZoneMode.opps
                            ? _c("polygon", {
                                attrs: {
                                  transform:
                                    "translate(" +
                                    k.victim.location.x * 1024 +
                                    ", " +
                                    k.victim.location.y * 1024 +
                                    ")",
                                  points: _vm.triangle(
                                    k.victim.location.x,
                                    k.victim.location.y,
                                    5
                                  ),
                                  fill: _vm.useLegend(
                                    k.victim.match_player_id,
                                    "black"
                                  )
                                }
                              })
                            : _vm._e()
                        ])
                      : _vm._e(),
                    k.type === "death" &&
                    _vm.kdZoneMode.view !== "kill" &&
                    (_vm.playerVisible(k.killer) ||
                      _vm.playerVisible(k.victim)) &&
                    _vm.firstFlag(k.first) &&
                    _vm.plantTimingFlag(k.pre_post_plant)
                      ? _c("g", { key: idx }, [
                          _vm.kdZoneMode.opps
                            ? _c("line", {
                                attrs: {
                                  x1: k.killer.location.x * 1024,
                                  y1: k.killer.location.y * 1024,
                                  x2: k.victim.location.x * 1024,
                                  y2: k.victim.location.y * 1024,
                                  stroke: "black",
                                  "stroke-width": "2"
                                }
                              })
                            : _vm._e(),
                          _vm.kdZoneMode.opps
                            ? _c("circle", {
                                attrs: {
                                  transform:
                                    "translate(" +
                                    k.killer.location.x * 1024 +
                                    ", " +
                                    k.killer.location.y * 1024 +
                                    ")",
                                  cx: k.killer.location.x,
                                  cy: k.killer.location.y,
                                  r: "4",
                                  fill: _vm.useLegend(
                                    k.killer.match_player_id,
                                    "black"
                                  )
                                }
                              })
                            : _vm._e(),
                          _c("polygon", {
                            attrs: {
                              transform:
                                "translate(" +
                                k.victim.location.x * 1024 +
                                ", " +
                                k.victim.location.y * 1024 +
                                ")",
                              points: _vm.triangle(
                                k.victim.location.x,
                                k.victim.location.y
                              ),
                              fill: _vm.useLegend(
                                k.victim.match_player_id,
                                "red"
                              )
                            }
                          })
                        ])
                      : _vm._e()
                  ]
                })
              ]
            : _vm._e()
        ],
        2
      )
    : _c(
        "g",
        [
          _c(
            "g",
            {
              attrs: {
                transform: "rotate(" + _vm.data.map.rotate_deg + " 512 512)"
              }
            },
            [
              _c("circle", {
                attrs: { cx: -20, cy: 900, r: "7", fill: "green" }
              }),
              _c(
                "text",
                {
                  attrs: {
                    fill: "white",
                    x: "0",
                    y: "870",
                    "font-size": "2rem"
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(this.data.teams[this.selected.team].name) + " "
                  )
                ]
              ),
              _c(
                "text",
                {
                  attrs: {
                    fill: "white",
                    x: "0",
                    y: "910",
                    "font-size": "2rem"
                  }
                },
                [_vm._v(" Kills: " + _vm._s(this.kdData.kills.kill) + " ")]
              ),
              _c("polygon", {
                attrs: {
                  transform: "rotate(90 -20, 930)",
                  points: _vm.triangle(-20, 930, 8),
                  fill: "red"
                }
              }),
              _c(
                "text",
                {
                  attrs: {
                    fill: "white",
                    x: "0",
                    y: "940",
                    "font-size": "2rem"
                  }
                },
                [_vm._v(" Deaths: " + _vm._s(this.kdData.kills.death) + " ")]
              ),
              _vm.kdZoneMode.legend
                ? _c(
                    "g",
                    _vm._l(this.presentPlayers, function(item, index) {
                      return _c(
                        "g",
                        {
                          key: item.id,
                          attrs: {
                            fill: _vm.legend[item.id],
                            transform:
                              "translate(1000, " +
                              (1014 - (index + 1) * 30) +
                              ")"
                          }
                        },
                        [
                          _vm._l(item.matchPlayerId, function(
                            matchPlayerId,
                            ind
                          ) {
                            return _c("g", { key: matchPlayerId }, [
                              _c("image", {
                                attrs: {
                                  x: -(ind * 30),
                                  y: "0",
                                  width: "26",
                                  height: "26",
                                  href: _vm.playerAgent(matchPlayerId)
                                    .display_icon_small_url
                                }
                              })
                            ])
                          }),
                          _c(
                            "text",
                            {
                              attrs: { x: "30", y: "20", "font-size": "1.5rem" }
                            },
                            [_vm._v(_vm._s(item.name))]
                          )
                        ],
                        2
                      )
                    }),
                    0
                  )
                : _vm._e()
            ]
          ),
          _vm._l(this.kdData.kills.list, function(k, idx) {
            return [
              k.type === "kill" &&
              _vm.kdZoneMode.view !== "death" &&
              (_vm.playerVisible(k.killer) || _vm.playerVisible(k.victim)) &&
              _vm.firstFlag(k.first) &&
              _vm.plantTimingFlag(k.pre_post_plant)
                ? _c("g", { key: idx }, [
                    _vm.kdZoneMode.opps
                      ? _c("line", {
                          attrs: {
                            x1: k.killer.location.x * 1024,
                            y1: k.killer.location.y * 1024,
                            x2: k.victim.location.x * 1024,
                            y2: k.victim.location.y * 1024,
                            stroke: "black",
                            "stroke-width": "2"
                          }
                        })
                      : _vm._e(),
                    _c("circle", {
                      attrs: {
                        transform:
                          "translate(" +
                          k.killer.location.x * 1024 +
                          ", " +
                          k.killer.location.y * 1024 +
                          ")",
                        cx: k.killer.location.x,
                        cy: k.killer.location.y,
                        r: "7",
                        fill: _vm.useLegend(k.killer.match_player_id, "green")
                      }
                    }),
                    _vm.kdZoneMode.opps
                      ? _c("polygon", {
                          attrs: {
                            transform:
                              "translate(" +
                              k.victim.location.x * 1024 +
                              ", " +
                              k.victim.location.y * 1024 +
                              ")",
                            points: _vm.triangle(
                              k.victim.location.x,
                              k.victim.location.y,
                              5
                            ),
                            fill: _vm.useLegend(
                              k.victim.match_player_id,
                              "black"
                            )
                          }
                        })
                      : _vm._e()
                  ])
                : _vm._e(),
              k.type === "death" &&
              _vm.kdZoneMode.view !== "kill" &&
              (_vm.playerVisible(k.killer) || _vm.playerVisible(k.victim)) &&
              _vm.firstFlag(k.first) &&
              _vm.plantTimingFlag(k.pre_post_plant)
                ? _c("g", { key: idx }, [
                    _vm.kdZoneMode.opps
                      ? _c("line", {
                          attrs: {
                            x1: k.killer.location.x * 1024,
                            y1: k.killer.location.y * 1024,
                            x2: k.victim.location.x * 1024,
                            y2: k.victim.location.y * 1024,
                            stroke: "black",
                            "stroke-width": "2"
                          }
                        })
                      : _vm._e(),
                    _vm.kdZoneMode.opps
                      ? _c("circle", {
                          attrs: {
                            transform:
                              "translate(" +
                              k.killer.location.x * 1024 +
                              ", " +
                              k.killer.location.y * 1024 +
                              ")",
                            cx: k.killer.location.x,
                            cy: k.killer.location.y,
                            r: "4",
                            fill: _vm.useLegend(
                              k.killer.match_player_id,
                              "black"
                            )
                          }
                        })
                      : _vm._e(),
                    _c("polygon", {
                      attrs: {
                        transform:
                          "translate(" +
                          k.victim.location.x * 1024 +
                          ", " +
                          k.victim.location.y * 1024 +
                          ")",
                        points: _vm.triangle(
                          k.victim.location.x,
                          k.victim.location.y
                        ),
                        fill: _vm.useLegend(k.victim.match_player_id, "red")
                      }
                    })
                  ])
                : _vm._e()
            ]
          })
        ],
        2
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }