var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _c("rect", {
      staticStyle: { cursor: "move" },
      attrs: {
        x: this.x,
        y: this.y,
        width: this.width,
        height: this.height,
        stroke: "red",
        "stroke-width": "2",
        fill: "lightblue",
        "fill-opacity": "0.5"
      },
      on: {
        mousedown: _vm.onMouseDownSelectorMove,
        mouseup: _vm.onMouseUpSelectorMove,
        mousemove: _vm.onMouseMoveSelectorMove
      }
    }),
    _c("circle", {
      staticStyle: { cursor: "pointer" },
      attrs: { cx: this.x, cy: this.y, r: "6", fill: "white" },
      on: {
        mousedown: function($event) {
          return _vm.onMouseDownResizeMove($event, "topleft")
        },
        mouseup: _vm.onMouseUpResizeMove,
        mousemove: _vm.onMouseMoveResizeMove
      }
    }),
    _c("circle", {
      staticStyle: { cursor: "pointer" },
      attrs: { cx: this.x, cy: this.y + this.height, r: "6", fill: "white" },
      on: {
        mousedown: function($event) {
          return _vm.onMouseDownResizeMove($event, "botleft")
        },
        mouseup: _vm.onMouseUpResizeMove,
        mousemove: _vm.onMouseMoveResizeMove
      }
    }),
    _c("circle", {
      staticStyle: { cursor: "pointer" },
      attrs: { cx: this.x + this.width, cy: this.y, r: "6", fill: "white" },
      on: {
        mousedown: function($event) {
          return _vm.onMouseDownResizeMove($event, "topright")
        },
        mouseup: _vm.onMouseUpResizeMove,
        mousemove: _vm.onMouseMoveResizeMove
      }
    }),
    _c("circle", {
      staticStyle: { cursor: "pointer" },
      attrs: {
        cx: this.x + this.width,
        cy: this.y + this.height,
        r: "6",
        fill: "white"
      },
      on: {
        mousedown: function($event) {
          return _vm.onMouseDownResizeMove($event, "botright")
        },
        mouseup: _vm.onMouseUpResizeMove,
        mousemove: _vm.onMouseMoveResizeMove
      }
    }),
    _vm.playerPercentage.length !== 0
      ? _c(
          "g",
          _vm._l(_vm.playerPercentage, function(item, index) {
            return _c(
              "g",
              {
                key: item.id,
                attrs: {
                  transform:
                    "translate(1024, " + (1014 - (index + 5) * 30) + ")"
                }
              },
              [
                _c(
                  "text",
                  {
                    attrs: {
                      x: "0",
                      y: "20",
                      stroke: "white",
                      fill: "white",
                      "font-size": "1.5rem"
                    }
                  },
                  [_vm._v(_vm._s(item.posPercentage.toFixed(2)) + " %")]
                ),
                _c("image", {
                  attrs: {
                    x: "120",
                    y: "0",
                    width: "26",
                    height: "26",
                    href: item.agentImg
                  }
                }),
                _c(
                  "text",
                  {
                    attrs: {
                      x: "160",
                      y: "20",
                      stroke: "white",
                      fill: "white",
                      "font-size": "1.5rem"
                    }
                  },
                  [_vm._v(_vm._s(item.name))]
                )
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }