var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3MapFilter",
    {
      attrs: { active: _vm.value !== null, label: "Round" },
      on: {
        click: function($event) {
          return _vm.toggle(_vm.value)
        }
      }
    },
    [
      _c(
        "V3Button",
        {
          attrs: { active: [true].includes(_vm.value) },
          on: {
            click: function($event) {
              return _vm.toggle(true)
            }
          }
        },
        [_vm._v(" Won ")]
      ),
      _c(
        "V3Button",
        {
          attrs: { active: [false].includes(_vm.value) },
          on: {
            click: function($event) {
              return _vm.toggle(false)
            }
          }
        },
        [_vm._v(" Lost ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }