var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "tef-container" }, [
    _c(
      "div",
      { staticClass: "tef-row" },
      [
        _c(
          "V3Button",
          {
            staticClass: "tef-team",
            attrs: { active: !!_vm.eco.length },
            on: { click: _vm.clear }
          },
          [
            _c("V3TeamLogo", {
              attrs: { grayscale: !_vm.eco.length, src: _vm.selectedTeam.image }
            }),
            _c("div", { staticClass: "tef-team-name" }, [
              _vm._v(_vm._s(_vm.selectedTeam.abbr || _vm.selectedTeam.name))
            ])
          ],
          1
        ),
        _c(
          "V3BtnGroup",
          [
            _c(
              "V3Button",
              {
                staticClass: "btn-eco",
                attrs: { title: "Pistol", active: _vm.eco.includes("P") },
                on: {
                  click: function($event) {
                    return _vm.toggleP()
                  }
                }
              },
              [_vm._v(" P ")]
            ),
            _c(
              "V3Button",
              {
                staticClass: "btn-eco",
                attrs: { title: "Eco 0-5k", active: _vm.eco.includes("$") },
                on: {
                  click: function($event) {
                    return _vm.toggle("$")
                  }
                }
              },
              [_vm._v(" $ ")]
            ),
            _c(
              "V3Button",
              {
                staticClass: "btn-eco",
                attrs: {
                  title: "Semi-eco 5-10k",
                  active: _vm.eco.includes("$$")
                },
                on: {
                  click: function($event) {
                    return _vm.toggle("$$")
                  }
                }
              },
              [_vm._v(" $$ ")]
            ),
            _c(
              "V3Button",
              {
                staticClass: "btn-eco",
                attrs: {
                  title: _vm.isScrim ? "Semi-buy 10-16.5k" : "Semi-buy 10-20k",
                  active: _vm.eco.includes("$$$")
                },
                on: {
                  click: function($event) {
                    return _vm.toggle("$$$")
                  }
                }
              },
              [_vm._v(" $$$ ")]
            ),
            _c(
              "V3Button",
              {
                staticClass: "btn-eco",
                attrs: {
                  title: _vm.isScrim ? "Full-buy 16.5k+" : "Full-buy 20k+",
                  active: _vm.eco.includes("$$$$")
                },
                on: {
                  click: function($event) {
                    return _vm.toggle("$$$$")
                  }
                }
              },
              [_vm._v(" $$$$ ")]
            )
          ],
          1
        )
      ],
      1
    ),
    !_vm.isScrim
      ? _c(
          "div",
          { staticClass: "tef-row" },
          [
            _c(
              "V3Button",
              {
                staticClass: "tef-team",
                attrs: { active: !!_vm.ecoOp.length },
                on: { click: _vm.clearOp }
              },
              [
                _c("V3TeamLogo", {
                  attrs: {
                    grayscale: !_vm.ecoOp.length,
                    src: _vm.opponentTeam.image
                  }
                }),
                _c("div", { staticClass: "tef-team-name" }, [
                  _vm._v(_vm._s(_vm.opponentTeam.abbr || _vm.opponentTeam.name))
                ])
              ],
              1
            ),
            _c(
              "V3BtnGroup",
              [
                _c(
                  "V3Button",
                  {
                    staticClass: "btn-eco",
                    attrs: { title: "Pistol", active: _vm.eco.includes("P") },
                    on: {
                      click: function($event) {
                        return _vm.toggleP()
                      }
                    }
                  },
                  [_vm._v(" P ")]
                ),
                _c(
                  "V3Button",
                  {
                    staticClass: "btn-eco",
                    attrs: {
                      title: "Eco 0-5k",
                      active: _vm.ecoOp.includes("$")
                    },
                    on: {
                      click: function($event) {
                        return _vm.toggleOp("$")
                      }
                    }
                  },
                  [_vm._v(" $ ")]
                ),
                _c(
                  "V3Button",
                  {
                    staticClass: "btn-eco",
                    attrs: {
                      title: "Semi-eco 5-10k",
                      active: _vm.ecoOp.includes("$$")
                    },
                    on: {
                      click: function($event) {
                        return _vm.toggleOp("$$")
                      }
                    }
                  },
                  [_vm._v(" $$ ")]
                ),
                _c(
                  "V3Button",
                  {
                    staticClass: "btn-eco",
                    attrs: {
                      title: "Semi-buy 10-20k",
                      active: _vm.ecoOp.includes("$$$")
                    },
                    on: {
                      click: function($event) {
                        return _vm.toggleOp("$$$")
                      }
                    }
                  },
                  [_vm._v(" $$$ ")]
                ),
                _c(
                  "V3Button",
                  {
                    staticClass: "btn-eco",
                    attrs: {
                      title: "Full-buy 20k+",
                      active: _vm.ecoOp.includes("$$$$")
                    },
                    on: {
                      click: function($event) {
                        return _vm.toggleOp("$$$$")
                      }
                    }
                  },
                  [_vm._v(" $$$$ ")]
                )
              ],
              1
            )
          ],
          1
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }