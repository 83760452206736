var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length
    ? _c(
        "g",
        _vm._l(_vm.items, function(item, index) {
          return _c(
            "g",
            {
              key: item.id,
              attrs: {
                fill: item.color,
                transform: "translate(1000, " + (1014 - (index + 1) * 30) + ")"
              }
            },
            [
              item.image
                ? _c("image", {
                    attrs: {
                      x: "0",
                      y: "2",
                      width: "26",
                      height: "26",
                      href: _vm._f("resolveUrl")(item.image)
                    }
                  })
                : _vm._e(),
              _c("text", { attrs: { x: "30", y: "20" } }, [
                _vm._v(" " + _vm._s(item.label) + " ")
              ])
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }