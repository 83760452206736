var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3Image",
    _vm._b(
      {
        attrs: {
          alt: _vm.agent.name,
          src: _vm.agent.display_icon_small_url,
          title: _vm.agent.name
        }
      },
      "V3Image",
      _vm.$attrs,
      false
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }