var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("V3Panel", { attrs: { title: "Selected Round Stats" } }, [
    _c("div", [
      _vm._v(
        "Rounds (W/T): " +
          _vm._s(this.statData.roundsWon) +
          " / " +
          _vm._s(this.statData.rounds)
      )
    ]),
    _c("div", { staticClass: "ps-table" }, [
      _c("div", { staticClass: "ps-table-header" }, [
        _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("PLR")]),
        _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("K")]),
        _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("D")]),
        !this.statData.gridFlag
          ? _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("A")])
          : _vm._e(),
        _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("TrK")]),
        _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("TrD")]),
        !this.statData.gridFlag
          ? _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("ADR")])
          : _vm._e()
      ]),
      !this.statData.gridFlag
        ? _c(
            "div",
            { staticClass: "ps-table-body" },
            [
              _vm._l(this.statData.playerStats, function(player, idx) {
                return [
                  _c("div", { key: idx, staticClass: "ps-table-row" }, [
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _c("img", {
                        staticClass: "ps-cell-player-icon",
                        attrs: { src: player.agent_icon_url }
                      }),
                      _vm._v(_vm._s(player.name) + " ")
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.kills))
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.deaths))
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.assists))
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.trade_kills))
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.traded_deaths))
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.damage))
                    ])
                  ])
                ]
              })
            ],
            2
          )
        : _c(
            "div",
            { staticClass: "ps-table-body" },
            [
              _vm._l(this.statData.playerStats, function(player, idx) {
                return [
                  _c("div", { key: idx, staticClass: "ps-table-row" }, [
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _c("img", {
                        staticClass: "ps-cell-player-icon",
                        attrs: { src: player.agent_icon_url }
                      }),
                      _vm._v(_vm._s(player.name) + " ")
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.kills))
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.deaths))
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.trade_kills))
                    ]),
                    _c("div", { staticClass: "ps-table-cell" }, [
                      _vm._v(_vm._s(player.traded_deaths))
                    ])
                  ])
                ]
              })
            ],
            2
          )
    ]),
    _c("div", { staticClass: "ts" }, [
      _c("div", [_vm._v("Opening Duels")]),
      _c("div", [
        _vm._v(
          "First Kills (W/T): " +
            _vm._s(this.statData.openingDuelsWins.fk) +
            " / " +
            _vm._s(this.statData.openingDuels.fk)
        )
      ]),
      _c("div", [
        _vm._v(
          "First Deaths (W/T): " +
            _vm._s(this.statData.openingDuelsWins.fd) +
            " / " +
            _vm._s(this.statData.openingDuels.fd)
        )
      ]),
      _c("div", { staticClass: "ps-table" }, [
        _c("div", { staticClass: "ps-table-header" }, [
          _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("PLR")]),
          _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("FK")]),
          _c("div", { staticClass: "ps-table-header-cell" }, [
            _vm._v("FK Win")
          ]),
          _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("FD")]),
          _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("FD Win")])
        ]),
        _c(
          "div",
          { staticClass: "ps-table-body" },
          [
            _vm._l(this.statData.playerStats, function(player, idx) {
              return [
                _c("div", { key: idx, staticClass: "ps-table-row" }, [
                  _c("div", { staticClass: "ps-table-cell" }, [
                    _c("img", {
                      staticClass: "ps-cell-player-icon",
                      attrs: { src: player.agent_icon_url }
                    }),
                    _vm._v(_vm._s(player.name) + " ")
                  ]),
                  _c("div", { staticClass: "ps-table-cell" }, [
                    _vm._v(_vm._s(player.fk))
                  ]),
                  _c("div", { staticClass: "ps-table-cell" }, [
                    _vm._v(_vm._s(player.fk_win))
                  ]),
                  _c("div", { staticClass: "ps-table-cell" }, [
                    _vm._v(_vm._s(player.fd))
                  ]),
                  _c("div", { staticClass: "ps-table-cell" }, [
                    _vm._v(_vm._s(player.fd_win))
                  ])
                ])
              ]
            })
          ],
          2
        )
      ])
    ]),
    _c(
      "div",
      { staticClass: "ws" },
      [
        _c("div", [_vm._v("Weapon Kill Breakdown")]),
        _vm._l(this.statData.playerStats, function(player, idx) {
          return [
            Object.keys(player.weapon_kills).length != 0
              ? _c("div", { key: idx, staticClass: "ws-header" }, [
                  _c("img", {
                    staticClass: "ws-icon",
                    attrs: { src: player.agent_icon_url }
                  }),
                  _vm._v(_vm._s(player.name) + " ")
                ])
              : _vm._e(),
            player.weapon_kills
              ? [
                  _vm._l(player.weapon_kills, function(item, key) {
                    return [
                      item.type === "Weapon"
                        ? _c(
                            "div",
                            {
                              key: key + idx + player.name,
                              staticClass: "weapon-kill-cell"
                            },
                            [
                              _c("img", {
                                staticClass: "ps-cell-weapon-icon",
                                attrs: {
                                  src:
                                    _vm.weaponsByName[key].kill_stream_icon_url,
                                  alt: _vm.weaponsByName[key].name
                                }
                              }),
                              _vm._v(" : " + _vm._s(item.count) + " ")
                            ]
                          )
                        : _vm._e(),
                      item.type === "Ability"
                        ? _c(
                            "div",
                            {
                              key: key + idx + player.name,
                              staticClass: "weapon-kill-cell"
                            },
                            [
                              _c("img", {
                                staticClass: "ps-cell-weapon-icon",
                                attrs: {
                                  src: _vm.getUtilityUrl(
                                    player.agent_name,
                                    key
                                  ),
                                  alt: key
                                }
                              }),
                              _vm._v(" : " + _vm._s(item.count) + " ")
                            ]
                          )
                        : _vm._e()
                    ]
                  })
                ]
              : _vm._e()
          ]
        })
      ],
      2
    ),
    _c(
      "div",
      { staticClass: "rs" },
      [
        _c("div", [_vm._v("XvY Round Stats (3s No Death)")]),
        _c("div", { staticClass: "rs-header" }, [
          _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("XvY")]),
          _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("Wins")]),
          _c("div", { staticClass: "ps-table-header-cell" }, [_vm._v("Total")])
        ]),
        _vm._l(this.statData.roundStates, function(xvy, idx) {
          return [
            _c("div", { key: idx, staticClass: "rs-header" }, [
              _c("div", [_vm._v(_vm._s(xvy[0]))]),
              _c("div", [_vm._v(_vm._s(xvy[1].wins))]),
              _c("div", [_vm._v(_vm._s(xvy[1].total))])
            ])
          ]
        })
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }