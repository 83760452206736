var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ts-container" },
    [
      _c(
        "div",
        { staticClass: "ts-row" },
        [
          _c(
            "V3Button",
            {
              staticClass: "ts-team-container",
              on: {
                click: function($event) {
                  _vm.expanded = !_vm.expanded
                }
              }
            },
            [
              _c("V3TeamLogo", {
                staticClass: "ts-team-logo",
                attrs: { size: "mdp", src: _vm.selectedTeam.image }
              }),
              _c("div", { staticClass: "ts-team-name" }, [
                _vm._v(_vm._s(_vm.selectedTeam.abbr || _vm.selectedTeam.name))
              ]),
              _c("IconArrowBack", {
                staticClass: "ts-team-expand",
                class: { expanded: _vm.expanded }
              })
            ],
            1
          ),
          _c(
            "V3BtnGroup",
            { staticClass: "ts-role-container" },
            [
              _c(
                "V3Button",
                {
                  staticClass: "atk",
                  attrs: { active: _vm.state.filters.round.teamRole === "atk" },
                  on: {
                    click: function($event) {
                      return _vm.select("atk", _vm.selectedTeam)
                    }
                  }
                },
                [_c("IconATK"), _vm._v(" ATK ")],
                1
              ),
              _c(
                "V3Button",
                {
                  staticClass: "def",
                  attrs: { active: _vm.state.filters.round.teamRole === "def" },
                  on: {
                    click: function($event) {
                      return _vm.select("def", _vm.selectedTeam)
                    }
                  }
                },
                [_c("IconDEF"), _vm._v(" DEF ")],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.expanded
        ? [
            _vm._l(_vm.data.teams, function(team, key) {
              return [
                team.id !== _vm.selectedTeam.id
                  ? _c(
                      "div",
                      { key: key, staticClass: "ts-row" },
                      [
                        _c(
                          "div",
                          { staticClass: "ts-team-container" },
                          [
                            _c("V3TeamLogo", {
                              staticClass: "ts-team-logo",
                              attrs: { size: "mdp", src: team.image }
                            }),
                            _c("div", { staticClass: "ts-team-name" }, [
                              _vm._v(_vm._s(team.abbr || team.name))
                            ])
                          ],
                          1
                        ),
                        _c(
                          "V3BtnGroup",
                          { staticClass: "ts-role-container" },
                          [
                            _c(
                              "V3Button",
                              {
                                staticClass: "atk",
                                attrs: {
                                  active:
                                    _vm.state.selected.team === team.id &&
                                    _vm.state.filters.round.teamRole === "atk"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.select("atk", team)
                                  }
                                }
                              },
                              [_c("IconATK"), _vm._v(" ATK ")],
                              1
                            ),
                            _c(
                              "V3Button",
                              {
                                staticClass: "def",
                                attrs: {
                                  active:
                                    _vm.state.selected.team === team.id &&
                                    _vm.state.filters.round.teamRole === "def"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.select("def", team)
                                  }
                                }
                              },
                              [_c("IconDEF"), _vm._v(" DEF ")],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ]
            })
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }