var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 48 48"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            stroke: "currentColor",
            "stroke-linecap": "round",
            "stroke-width": "4"
          }
        },
        [
          _c("path", { attrs: { d: "M8 10V38" } }),
          _c("path", { attrs: { d: "M24 4V16" } }),
          _c("path", { attrs: { d: "M16 24H42" } }),
          _c("path", {
            attrs: {
              "stroke-linejoin": "round",
              d: "M37.0561 19.0113L42.0929 24.0255L37.0561 29.123"
            }
          }),
          _c("path", { attrs: { d: "M24 32V44" } })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }