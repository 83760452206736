var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "v3-container",
      class: { visible: _vm.visible },
      style: _vm.styles
    },
    [
      _c("transition", { attrs: { name: "slide" } }, [
        _vm.visible
          ? _c(
              "div",
              { ref: "content", staticClass: "v3-container-content" },
              [_vm._t("default")],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }