var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: "1em",
        height: "1em",
        viewBox: "0 0 24 24",
        fill: "currentColor"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "m23.45 23-1.31-.53a30.2 30.2 0 0 0 -10.14-1.88 30.2 30.2 0 0 0 -10.14 1.84l-1.31.57v-22l1.31.53a30.2 30.2 0 0 0 10.14 1.88 30.2 30.2 0 0 0 10.14-1.84l1.31-.57zm-11.45-4.32a32.2 32.2 0 0 1 9.55 1.52v-16.4a32.2 32.2 0 0 1 -9.55 1.52 32.2 32.2 0 0 1 -9.55-1.52v16.4a32.2 32.2 0 0 1 9.55-1.52z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "m11.05 16.77h-5.73v-2.3l3.5-3.47a1.06 1.06 0 0 0 -.75-1.82.83.83 0 0 0 -.84.84v.11h-1.91v-.13a2.75 2.75 0 0 1 2.75-2.77 3 3 0 0 1 3 3 2.93 2.93 0 0 1 -.88 2.1l-2.57 2.53h3.43z"
        }
      }),
      _c("path", {
        attrs: {
          d:
            "m15.82 16.77h-2.82v-9.54h2.87a2.86 2.86 0 0 1 2.86 2.86v3.82a2.86 2.86 0 0 1 -2.91 2.86zm-1-1.91h1a1 1 0 0 0 .95-.95v-3.82a1 1 0 0 0 -.95-.95h-1z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }