var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3Panel",
    {
      attrs: {
        expandable: "",
        expanded: _vm.expanded,
        title: "Ultimate orbs at start",
        variant: "secondary"
      },
      on: {
        "update:expanded": function($event) {
          _vm.expanded = $event
        }
      }
    },
    [
      _c(
        "V3Container",
        { staticClass: "orbs-filter", attrs: { visible: _vm.expanded } },
        _vm._l(_vm.teams, function(team) {
          return _c(
            "div",
            { key: team.id, staticClass: "team-orbs" },
            [
              _c(
                "V3Button",
                {
                  staticClass: "team-orbs-header",
                  attrs: { active: _vm.isTeamActive(team) }
                },
                [
                  _c("V3TeamLogo", {
                    staticClass: "team-orbs-header-logo",
                    attrs: {
                      grayscale: !_vm.isTeamActive(team),
                      src: team.image
                    }
                  }),
                  _c("div", { staticClass: "team-orbs-header-name" }, [
                    _vm._v(_vm._s(team.name))
                  ])
                ],
                1
              ),
              _vm._l(_vm.teamPlayers(team.id), function(orbPlayer) {
                return [
                  _c(
                    "V3MapFilter",
                    {
                      key: orbPlayer.id,
                      staticClass: "player",
                      attrs: {
                        active: _vm.isActive(orbPlayer),
                        icon:
                          _vm.data.agents[orbPlayer.agentId]
                            .display_icon_small_url,
                        label: _vm.data.players[orbPlayer.playerId].name
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggle(orbPlayer)
                        }
                      }
                    },
                    _vm._l(_vm.getOrbGroups(orbPlayer), function(group, idx) {
                      return _c(
                        "V3Button",
                        {
                          key: orbPlayer.id + " + " + idx,
                          attrs: {
                            active: _vm.isGroupActive(orbPlayer, group)
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggle(orbPlayer, group)
                            }
                          }
                        },
                        [
                          group.label
                            ? [_vm._v(" " + _vm._s(group.label) + " ")]
                            : _vm._e(),
                          group.image
                            ? [
                                _c("V3Image", {
                                  staticClass: "ability",
                                  attrs: { src: group.image }
                                })
                              ]
                            : _vm._e()
                        ],
                        2
                      )
                    }),
                    1
                  )
                ]
              })
            ],
            2
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }