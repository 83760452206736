var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3Panel",
    { staticClass: "kd-filter-panel", attrs: { title: "KD Zone Filter" } },
    [
      this.data.map.name === "Ascent" && this.data.zones
        ? _c(
            "V3BtnGroup",
            [
              _c(
                "V3Button",
                {
                  attrs: { active: this.state.kdZoneMode.zone },
                  on: {
                    click: function() {
                      return (this$1.state.kdZoneMode.zone = !this$1.state
                        .kdZoneMode.zone)
                    }
                  }
                },
                [_vm._v(" Zone Mode ")]
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "V3BtnGroup",
        [
          _c(
            "V3Button",
            {
              attrs: { active: this.state.kdZoneMode.view === "all" },
              on: {
                click: function($event) {
                  return _vm.setViewMode("all")
                }
              }
            },
            [_vm._v(" Display All ")]
          ),
          _c(
            "V3Button",
            {
              attrs: { active: this.state.kdZoneMode.view === "kill" },
              on: {
                click: function($event) {
                  return _vm.setViewMode("kill")
                }
              }
            },
            [_vm._v(" Kills Only ")]
          ),
          _c(
            "V3Button",
            {
              attrs: { active: this.state.kdZoneMode.view === "death" },
              on: {
                click: function($event) {
                  return _vm.setViewMode("death")
                }
              }
            },
            [_vm._v(" Deaths Only ")]
          )
        ],
        1
      ),
      _c(
        "V3BtnGroup",
        [
          _c(
            "V3Button",
            {
              attrs: { active: this.state.kdZoneMode.plantTime === "all" },
              on: {
                click: function($event) {
                  return _vm.setPlantTime("all")
                }
              }
            },
            [_vm._v(" All ")]
          ),
          _c(
            "V3Button",
            {
              attrs: { active: this.state.kdZoneMode.plantTime === "pre" },
              on: {
                click: function($event) {
                  return _vm.setPlantTime("pre")
                }
              }
            },
            [_vm._v(" Pre-Plant ")]
          ),
          _c(
            "V3Button",
            {
              attrs: { active: this.state.kdZoneMode.plantTime === "post" },
              on: {
                click: function($event) {
                  return _vm.setPlantTime("post")
                }
              }
            },
            [_vm._v(" Post-Plant ")]
          )
        ],
        1
      ),
      _c(
        "V3BtnGroup",
        [
          _c(
            "V3Button",
            {
              attrs: { active: this.state.kdZoneMode.opps },
              on: {
                click: function($event) {
                  return _vm.setShowOpps(true)
                }
              }
            },
            [_vm._v(" Display Opponents ")]
          ),
          _c(
            "V3Button",
            {
              attrs: { active: !this.state.kdZoneMode.opps },
              on: {
                click: function($event) {
                  return _vm.setShowOpps(false)
                }
              }
            },
            [_vm._v(" Hide Opponents ")]
          )
        ],
        1
      ),
      _c(
        "V3BtnGroup",
        [
          _c(
            "V3Button",
            {
              attrs: { active: this.state.kdZoneMode.first },
              on: {
                click: function() {
                  return (this$1.state.kdZoneMode.first = !this$1.state
                    .kdZoneMode.first)
                }
              }
            },
            [_vm._v("Only Display FK/FD")]
          )
        ],
        1
      ),
      _c(
        "V3BtnGroup",
        [
          _c(
            "V3Button",
            {
              attrs: { active: this.state.kdZoneMode.legend },
              on: {
                click: function() {
                  return (this$1.state.kdZoneMode.legend = !this$1.state
                    .kdZoneMode.legend)
                }
              }
            },
            [_vm._v("Display Player Color")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }