var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3MapFilter",
    {
      attrs: { active: _vm.value !== null, label: "Outcome" },
      on: {
        click: function($event) {
          return _vm.update(null)
        }
      }
    },
    _vm._l(_vm.options, function(option, key) {
      return _c(
        "V3Button",
        {
          key: key,
          attrs: { active: _vm.value === option.value },
          on: {
            click: function($event) {
              return _vm.toggle(option.value)
            }
          }
        },
        [
          option.icon
            ? _c(option.icon, { tag: "component", staticClass: "rof-icon" })
            : _vm._e()
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }