var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-filters-container" },
    [
      _c("div", { staticClass: "team-select" }, [_c("TeamSelect")], 1),
      _c(
        "V3MapFilter",
        [
          _c(
            "V3Button",
            {
              staticClass: "clear-filters",
              attrs: { active: _vm.isFilterActive },
              on: { click: _vm.clearFilters }
            },
            [_c("IconArrowRepeat"), _vm._v(" Clear all filters ")],
            1
          )
        ],
        1
      ),
      _c(
        "V3ScrollableContainer",
        { staticClass: "map-filters", attrs: { vertical: "" } },
        [
          _c(
            "V3Panel",
            {
              attrs: {
                title: "Scenario Search",
                expandable: "",
                expanded: _vm.scenarioExpanded
              },
              on: {
                "update:expanded": function($event) {
                  _vm.scenarioExpanded = $event
                }
              }
            },
            [
              _c(
                "V3Container",
                { attrs: { visible: _vm.scenarioExpanded } },
                [
                  _c(
                    "V3BtnGroup",
                    [
                      _c(
                        "V3Button",
                        {
                          staticStyle: {
                            "background-color": "#191822",
                            color: "white",
                            width: "75%",
                            "align-self": "center",
                            margin: "4px"
                          },
                          attrs: { active: true },
                          on: {
                            click: function($event) {
                              return _vm.$emit("new-player-snapshot")
                            }
                          }
                        },
                        [_vm._v(" Add Player Snapshot ")]
                      ),
                      _vm.hasUtilitySmokeWall
                        ? _c(
                            "V3Button",
                            {
                              staticStyle: {
                                "background-color": "#191822",
                                color: "white",
                                width: "75%",
                                "align-self": "center",
                                margin: "4px"
                              },
                              attrs: { active: true },
                              on: {
                                click: function($event) {
                                  return _vm.$emit("new-util-snapshot")
                                }
                              }
                            },
                            [_vm._v(" Add Utility Snapshot ")]
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "snapshot-container" },
                    [
                      _vm._l(
                        _vm.state.filters.round.scenario.snapshots,
                        function(snapshot, idx) {
                          return [
                            snapshot.type === "player"
                              ? _c(
                                  "div",
                                  { key: idx, staticClass: "snapshot" },
                                  [
                                    _vm._l(snapshot.players, function(plr, i) {
                                      return [
                                        _c(
                                          "div",
                                          {
                                            key: plr.player.id + i,
                                            class: plr.player.side
                                          },
                                          [
                                            _c("img", {
                                              staticClass: "agent-icon",
                                              attrs: {
                                                src: plr.player.agentImg,
                                                alt: plr.player.agent
                                              }
                                            })
                                          ]
                                        )
                                      ]
                                    }),
                                    _c(
                                      "V3Button",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "margin-left": "auto",
                                          "margin-right": "16px"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteSnapshot(idx)
                                          }
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ],
                                  2
                                )
                              : snapshot.type === "utility"
                              ? _c(
                                  "div",
                                  { key: idx, staticClass: "snapshot" },
                                  [
                                    _vm._l(snapshot.utility, function(util, i) {
                                      return [
                                        "smoke" in util
                                          ? _c(
                                              "div",
                                              {
                                                key: "smoke" + i,
                                                staticStyle: {
                                                  "background-color": "black",
                                                  "padding-left": "8px",
                                                  "padding-right": "8px",
                                                  "border-radius": "0.75rem"
                                                }
                                              },
                                              [_vm._v(" S ")]
                                            )
                                          : _vm._e(),
                                        "wall" in util
                                          ? _c(
                                              "div",
                                              {
                                                key: "wall" + i,
                                                staticStyle: {
                                                  "background-color": "black",
                                                  "padding-left": "8px",
                                                  "padding-right": "8px",
                                                  "border-radius": "0.75rem"
                                                }
                                              },
                                              [_vm._v(" W ")]
                                            )
                                          : _vm._e(),
                                        "utility" in util
                                          ? _c("div", { key: "utility" + i }, [
                                              _c(
                                                "div",
                                                {
                                                  key:
                                                    util.utility.utilityName +
                                                    i,
                                                  class: util.utility.side
                                                },
                                                [
                                                  _c("img", {
                                                    staticClass: "agent-icon",
                                                    attrs: {
                                                      src:
                                                        util.utility.utilityImg,
                                                      alt:
                                                        util.utility.utilityName
                                                    }
                                                  })
                                                ]
                                              )
                                            ])
                                          : _vm._e()
                                      ]
                                    }),
                                    _c(
                                      "V3Button",
                                      {
                                        staticStyle: {
                                          color: "red",
                                          "margin-left": "auto",
                                          "margin-right": "16px"
                                        },
                                        on: {
                                          click: function($event) {
                                            return _vm.deleteSnapshot(idx)
                                          }
                                        }
                                      },
                                      [_vm._v("Delete")]
                                    )
                                  ],
                                  2
                                )
                              : _vm._e()
                          ]
                        }
                      )
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          !_vm.isScrim
            ? _c(
                "V3Panel",
                {
                  attrs: {
                    title: "Preset Round Filters",
                    expandable: "",
                    expanded: _vm.presetExpanded
                  },
                  on: {
                    "update:expanded": function($event) {
                      _vm.presetExpanded = $event
                    }
                  }
                },
                [
                  _c(
                    "V3Container",
                    { attrs: { visible: _vm.presetExpanded } },
                    [
                      _vm._l(this.presetFilters, function(preset, i) {
                        return [
                          _vm.checkPreset(preset.filter)
                            ? _c(
                                "div",
                                { key: "preset" + i, staticClass: "preset" },
                                [
                                  _vm.renamePreset === i
                                    ? _c("input", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value: preset.name,
                                            expression: "preset.name"
                                          }
                                        ],
                                        staticStyle: {
                                          width: "60%",
                                          "font-size": "0.75rem",
                                          "line-height": "1rem"
                                        },
                                        attrs: { type: "text" },
                                        domProps: { value: preset.name },
                                        on: {
                                          input: function($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              preset,
                                              "name",
                                              $event.target.value
                                            )
                                          }
                                        }
                                      })
                                    : _c(
                                        "V3Button",
                                        {
                                          staticStyle: {
                                            width: "60%",
                                            "background-color": "#191822",
                                            color: "white",
                                            padding: "4px",
                                            "font-size": "0.75rem",
                                            "line-height": "1rem"
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.loadPreset(
                                                preset.filter
                                              )
                                            }
                                          }
                                        },
                                        [_vm._v(_vm._s(preset.name))]
                                      ),
                                  _c(
                                    "V3BtnGroup",
                                    { staticStyle: { "margin-left": "auto" } },
                                    [
                                      _vm.renamePreset === false
                                        ? _c(
                                            "V3Button",
                                            {
                                              on: {
                                                click: function($event) {
                                                  _vm.renamePreset = i
                                                }
                                              }
                                            },
                                            [_vm._v("Rename")]
                                          )
                                        : _vm._e(),
                                      _vm.renamePreset === i
                                        ? _c(
                                            "V3Button",
                                            {
                                              attrs: {
                                                disabled: !preset.name.length,
                                                active: !preset.name.length
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.savePreset()
                                                }
                                              }
                                            },
                                            [_vm._v("Save")]
                                          )
                                        : _vm._e(),
                                      _vm.renamePreset === false
                                        ? _c(
                                            "V3Button",
                                            {
                                              staticStyle: {
                                                color: "red",
                                                "margin-right": "16px"
                                              },
                                              on: {
                                                click: function($event) {
                                                  return _vm.deletePreset(i)
                                                }
                                              }
                                            },
                                            [_vm._v("Delete")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      })
                    ],
                    2
                  )
                ],
                1
              )
            : _vm._e(),
          _c(
            "V3Panel",
            {
              staticClass: "map-filters-list",
              attrs: {
                icon: _vm.$options.components.IconTarget,
                title: "Rounds",
                expandable: "",
                expanded: _vm.roundsExpanded
              },
              on: {
                "update:expanded": function($event) {
                  _vm.roundsExpanded = $event
                }
              }
            },
            [
              _c(
                "V3Container",
                { attrs: { visible: _vm.roundsExpanded } },
                [
                  _c("HalvesFilter", {
                    attrs: { data: _vm.data },
                    model: {
                      value: _vm.state.filters.round.half,
                      callback: function($$v) {
                        _vm.$set(_vm.state.filters.round, "half", $$v)
                      },
                      expression: "state.filters.round.half"
                    }
                  }),
                  _c("FKDFilter", {
                    attrs: { data: _vm.data },
                    model: {
                      value: _vm.state.filters.round.fkd,
                      callback: function($$v) {
                        _vm.$set(_vm.state.filters.round, "fkd", $$v)
                      },
                      expression: "state.filters.round.fkd"
                    }
                  }),
                  _vm.hasPlants
                    ? _c("BombsiteSelector", {
                        attrs: {
                          "bombsites-count": _vm.data.map.bombsites.length
                        },
                        model: {
                          value: _vm.state.filters.round.site,
                          callback: function($$v) {
                            _vm.$set(_vm.state.filters.round, "site", $$v)
                          },
                          expression: "state.filters.round.site"
                        }
                      })
                    : _vm._e(),
                  _vm.hasOutcome
                    ? _c("RoundOutcomeFilter", {
                        attrs: { data: _vm.data, selected: _vm.state.selected },
                        model: {
                          value: _vm.state.filters.round.outcome,
                          callback: function($$v) {
                            _vm.$set(_vm.state.filters.round, "outcome", $$v)
                          },
                          expression: "state.filters.round.outcome"
                        }
                      })
                    : _vm._e(),
                  _vm.hasWins
                    ? _c("RoundWinFilter", {
                        attrs: { data: _vm.data, selected: _vm.state.selected },
                        model: {
                          value: _vm.state.filters.round.win,
                          callback: function($$v) {
                            _vm.$set(_vm.state.filters.round, "win", $$v)
                          },
                          expression: "state.filters.round.win"
                        }
                      })
                    : _vm._e(),
                  _vm.hasEconomy
                    ? _c("TeamEcoFilter", {
                        attrs: {
                          data: _vm.data,
                          "is-scrim": _vm.isScrim,
                          selected: _vm.state.filters.round,
                          "selected-team-id": _vm.state.selected.team
                        }
                      })
                    : _vm._e(),
                  _c("OperatorFilter", {
                    attrs: { data: _vm.data, selected: _vm.state.selected },
                    model: {
                      value: _vm.state.filters.round.operator,
                      callback: function($$v) {
                        _vm.$set(_vm.state.filters.round, "operator", $$v)
                      },
                      expression: "state.filters.round.operator"
                    }
                  }),
                  _c("TradesFilter", {
                    attrs: { data: _vm.data, selected: _vm.state.selected },
                    model: {
                      value: _vm.state.filters.round.trades,
                      callback: function($$v) {
                        _vm.$set(_vm.state.filters.round, "trades", $$v)
                      },
                      expression: "state.filters.round.trades"
                    }
                  }),
                  _vm.hasOrbs
                    ? _c("OrbsFilter", {
                        attrs: {
                          data: _vm.data,
                          "selected-team-id": _vm.state.selected.team,
                          "v-model": _vm.state.filters.round.orbs
                        }
                      })
                    : _vm._e(),
                  _c(
                    "V3BtnGroup",
                    [
                      _c(
                        "V3Button",
                        {
                          staticStyle: {
                            "background-color": "#191822",
                            color: "white",
                            width: "75%",
                            "align-self": "center",
                            margin: "4px"
                          },
                          attrs: { active: true },
                          on: { click: _vm.addPreset }
                        },
                        [_vm._v(" Save as Preset Round Filter ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "V3Panel",
            {
              staticClass: "map-filters-list",
              attrs: {
                icon: _vm.$options.components.IconTimelineEvent,
                expandable: "",
                expanded: _vm.eventsExpanded,
                title: "Time/Event"
              },
              on: {
                "update:expanded": function($event) {
                  _vm.eventsExpanded = $event
                }
              }
            },
            [
              _c(
                "V3Container",
                { attrs: { visible: _vm.eventsExpanded } },
                [
                  _c("WeaponAbilitySelector", {
                    attrs: { data: _vm.data },
                    model: {
                      value: _vm.state.filters.timeline.killWeapons,
                      callback: function($$v) {
                        _vm.$set(_vm.state.filters.timeline, "killWeapons", $$v)
                      },
                      expression: "state.filters.timeline.killWeapons"
                    }
                  }),
                  _vm.hasAbilitiesUsage ? _c("AbilitiesUsageFilter") : _vm._e(),
                  false
                    ? _c(
                        "FilterContainer",
                        { attrs: { label: "Positions" } },
                        [
                          _c("PositionsFilter", {
                            attrs: { value: _vm.state.selected.positions },
                            on: {
                              change: function($event) {
                                return _vm.update("positions", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          false
            ? _c(
                "FilterContainer",
                {
                  staticClass: "section-container",
                  attrs: { label: "Display -> Move to left sidebar" }
                },
                [
                  _c(
                    "FilterContainer",
                    { attrs: { label: "Kills" } },
                    [
                      _c("KillsFilter", {
                        attrs: { selected: _vm.state.selected },
                        on: {
                          "update:selected": function($event) {
                            return _vm.$emit("update:selected", $event)
                          }
                        }
                      })
                    ],
                    1
                  ),
                  _vm.hasZones
                    ? _c(
                        "FilterContainer",
                        { attrs: { label: "Zones" } },
                        [
                          _c("ZoneFilter", {
                            attrs: {
                              data: _vm.data,
                              selected: _vm.state.selected
                            },
                            on: {
                              "update:selected": function($event) {
                                return _vm.$emit("update:selected", $event)
                              }
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }