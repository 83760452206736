var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "add-note",
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.submit.apply(null, arguments)
        }
      }
    },
    [
      _c(
        "b-form-group",
        { attrs: { label: "Comment" } },
        [
          _c("b-form-input", {
            ref: "label",
            attrs: { autofocus: "", required: "" },
            model: {
              value: _vm.note.text,
              callback: function($$v) {
                _vm.$set(_vm.note, "text", $$v)
              },
              expression: "note.text"
            }
          })
        ],
        1
      ),
      _c(
        "V3BtnGroup",
        _vm._l(_vm.iconOptions, function(option, key) {
          return _c(
            "V3Button",
            {
              key: key,
              attrs: { active: _vm.note.icon === option.value },
              on: {
                click: function($event) {
                  return _vm.toggleIcon(option.value)
                }
              }
            },
            [
              option.icon
                ? _c(option.icon, { tag: "component" })
                : _c("img", {
                    staticClass: "bookmark-icon",
                    attrs: { src: option.asset, width: "1em", height: "1em" }
                  })
            ],
            1
          )
        }),
        1
      ),
      false
        ? _c(
            "b-form-group",
            { attrs: { label: "Tags" } },
            [
              _c("TagsDropdown", {
                attrs: { options: _vm.noteTags, selected: _vm.note.tags },
                on: {
                  "update:selected": function($event) {
                    return _vm.$set(_vm.note, "tags", $event)
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.mapMode === "replay2d"
        ? _c(
            "b-form-checkbox",
            {
              attrs: { switch: "" },
              model: {
                value: _vm.note.startPlaying,
                callback: function($$v) {
                  _vm.$set(_vm.note, "startPlaying", $$v)
                },
                expression: "note.startPlaying"
              }
            },
            [_vm._v("Start playing")]
          )
        : _vm._e(),
      _c(
        "V3BtnGroup",
        [
          _c(
            "V3Button",
            { on: { click: _vm.$listeners.cancel } },
            [_c("b-icon-x"), _vm._v(" Cancel ")],
            1
          ),
          _c(
            "V3Button",
            { attrs: { type: "submit", disabled: !_vm.note.text } },
            [_c("b-icon-journal-plus"), _vm._v(" Save ")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }