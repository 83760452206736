var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "g",
    { attrs: { opacity: _vm.props.faded ? _vm.$options.meta.fadeOpacity : 1 } },
    _vm._l(_vm.props.event.vector.length - 1, function(index) {
      return _c(_vm.$options.components.MapLineEntry, {
        key: index,
        tag: "component",
        attrs: {
          color: _vm._f("roleColor")(
            _vm.props.data.roundTeams[_vm.props.event.round_team_id].role
          ),
          x1: _vm.props.event.vector[index - 1].x,
          y1: _vm.props.event.vector[index - 1].y,
          x2: _vm.props.event.vector[index].x,
          y2: _vm.props.event.vector[index].y,
          width: "4"
        }
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }