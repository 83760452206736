var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "g",
    {
      attrs: {
        transform:
          "translate(" +
          _vm.props.event.location.x * 1024 +
          "," +
          _vm.props.event.location.y * 1024 +
          ")"
      }
    },
    [
      _c("g", { attrs: { transform: "scale(1.3)" } }, [
        _c("path", {
          staticClass: "death",
          attrs: {
            stroke: _vm._f("roleColor")(
              _vm.props.data.roundTeams[_vm.props.event.round_team_id].role,
              1
            ),
            d: "M-10,-10l20,20m0,-20l-20,20"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }