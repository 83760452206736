var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 512 512",
        fill: "currentColor"
      }
    },
    [
      _c("g", { attrs: { fill: "currentColor" } }, [
        _c("path", {
          attrs: {
            d:
              "M435.2,116.7C387,80.8,323.4,61,256,61c-67.4,0-131,19.8-179.2,55.7C27.3,153.6,0,203.1,0,256\n\ts27.3,102.4,76.8,139.3C125,431.2,188.6,451,256,451c67.4,0,131-19.8,179.2-55.7C484.7,358.4,512,308.9,512,256\n\tS484.7,153.6,435.2,116.7z M417.2,371.3C374.2,403.3,317,421,256,421c-61,0-118.2-17.7-161.2-49.7C53,340.1,30,299.2,30,256\n\ts23-84.1,64.8-115.3C137.8,108.7,195,91,256,91c61,0,118.2,17.7,161.2,49.7C459,171.9,482,212.8,482,256S459,340.1,417.2,371.3z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }