var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 256 256"
      }
    },
    [
      _c("path", {
        attrs: {
          fill: "currentColor",
          d:
            "M129.865 255.306a5.637 5.637 0 0 1-5.073-3.157a5.355 5.355 0 0 1 .507-5.637l59.98-82.584l-105.02 42.899a5.778 5.778 0 0 1-2.255.479a5.637 5.637 0 0 1-5.384-4.059a5.412 5.412 0 0 1 2.311-6.172l92.365-54.54l-162.632-9.357a5.637 5.637 0 0 1 0-11.106l162.717-9.33l-92.393-54.538a5.412 5.412 0 0 1-2.311-6.173a5.637 5.637 0 0 1 5.355-4.059a5.18 5.18 0 0 1 2.255.507l105.048 42.955l-59.98-82.555a5.355 5.355 0 0 1-.507-5.638A5.637 5.637 0 0 1 129.894 0c1.48.01 2.894.62 3.917 1.691L253.347 121.2a9.076 9.076 0 0 1 0 12.824L133.755 253.672a5.442 5.442 0 0 1-3.89 1.634Z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }