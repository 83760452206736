var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3Panel",
    {
      staticClass: "k-container",
      attrs: {
        expandable: "",
        expanded: _vm.expanded,
        title: "Kills with",
        variant: "secondary"
      },
      on: {
        "update:expanded": function($event) {
          _vm.expanded = $event
        }
      }
    },
    [
      _c(
        "V3Container",
        { staticClass: "k-filter", attrs: { visible: _vm.expanded } },
        [
          _c(
            "V3MapFilter",
            { staticClass: "k-list-container" },
            [
              _vm._l(_vm.options, function(option) {
                return [
                  !option.disabled
                    ? _c(
                        "V3Button",
                        {
                          key: option.value,
                          staticClass: "k-item",
                          attrs: {
                            active: _vm.checked[option.value],
                            disabled: option.disabled,
                            title: option.text
                          },
                          on: {
                            click: function($event) {
                              return _vm.toggle(option)
                            }
                          }
                        },
                        [
                          _c("V3Image", {
                            staticClass: "k-item-icon",
                            attrs: {
                              alt: option.text,
                              gray: !_vm.checked[option.value],
                              src: option.icon
                            }
                          }),
                          _c("div", { staticClass: "k-item-label" }, [
                            _vm._v(_vm._s(option.text))
                          ])
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }