var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3MapFilter",
    {
      attrs: { active: _vm.value != null, label: "First K/D" },
      on: {
        click: function($event) {
          return _vm.$emit("change", null)
        }
      }
    },
    _vm._l(_vm.options, function(option, key) {
      return _c(
        "V3Button",
        {
          key: key,
          attrs: { active: option.value === _vm.value },
          on: {
            click: function($event) {
              return _vm.$emit(
                "change",
                option.value === _vm.value ? null : option.value
              )
            }
          }
        },
        [_vm._v(" " + _vm._s(option.text) + " ")]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }