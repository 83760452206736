var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "map-modal" }, [
    _c("div", { staticClass: "map-content" }, [
      _c(
        "div",
        { staticClass: "settings-container" },
        [
          _c(
            "V3BtnGroup",
            [
              _vm.hasUtilities
                ? _c(
                    "V3Button",
                    {
                      staticClass: "utility-button",
                      attrs: { active: true },
                      on: {
                        click: function($event) {
                          return _vm.addUtility("utility")
                        }
                      }
                    },
                    [_vm._v("Add Utility")]
                  )
                : _vm._e(),
              _vm.hasSmokes
                ? _c(
                    "V3Button",
                    {
                      staticClass: "utility-button",
                      attrs: { active: !_vm.hasSmoke, disabled: _vm.hasSmoke },
                      on: {
                        click: function($event) {
                          return _vm.addUtility("smoke")
                        }
                      }
                    },
                    [_vm._v("Add Smoke")]
                  )
                : _vm._e(),
              _vm.hasWalls
                ? _c(
                    "V3Button",
                    {
                      staticClass: "utility-button",
                      attrs: { active: !_vm.hasWall, disabled: _vm.hasWall },
                      on: {
                        click: function($event) {
                          return _vm.addUtility("wall")
                        }
                      }
                    },
                    [_vm._v("Add Wall")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._l(this.utilitySearch, function(util, idx) {
            return [
              "smoke" in util
                ? _c(
                    "div",
                    { key: idx, staticClass: "utility-container" },
                    [
                      _c("span", { staticClass: "smoke-or-wall" }, [
                        _vm._v("Smoke")
                      ]),
                      _c(
                        "V3Button",
                        {
                          staticClass: "delete-button",
                          on: {
                            click: function($event) {
                              return _vm.deleteUtility(idx)
                            }
                          }
                        },
                        [_vm._v("DELETE")]
                      )
                    ],
                    1
                  )
                : "wall" in util
                ? _c(
                    "div",
                    { key: idx, staticClass: "utility-container" },
                    [
                      _c("span", { staticClass: "smoke-or-wall" }, [
                        _vm._v("Wall")
                      ]),
                      _c(
                        "V3Button",
                        {
                          staticClass: "delete-button",
                          on: {
                            click: function($event) {
                              return _vm.deleteUtility(idx)
                            }
                          }
                        },
                        [_vm._v("DELETE")]
                      )
                    ],
                    1
                  )
                : "utility" in util
                ? _c(
                    "div",
                    { key: "util" + idx, staticClass: "utility-container" },
                    [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: util.utility,
                              expression: "util.utility"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                util,
                                "utility",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        _vm._l(_vm.utilitySideList, function(u) {
                          return _c(
                            "option",
                            {
                              key: u.side + u.agent + u.utilityName,
                              domProps: { value: u }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(u.side.toUpperCase()) +
                                  " - " +
                                  _vm._s(u.agent) +
                                  " - " +
                                  _vm._s(u.utilityName) +
                                  " "
                              )
                            ]
                          )
                        }),
                        0
                      ),
                      util.utility
                        ? _c(
                            "span",
                            { staticClass: "role", class: [util.utility.side] },
                            [_vm._v(_vm._s(util.utility.side))]
                          )
                        : _vm._e(),
                      _c(
                        "V3Button",
                        {
                          staticClass: "delete-button",
                          on: {
                            click: function($event) {
                              return _vm.deleteUtility(idx)
                            }
                          }
                        },
                        [_vm._v("DELETE")]
                      )
                    ],
                    1
                  )
                : _vm._e()
            ]
          }),
          _c(
            "div",
            { staticClass: "settings-button-group" },
            [
              _c(
                "V3Button",
                {
                  staticClass: "normal-button",
                  on: {
                    click: function($event) {
                      return _vm.closeModal()
                    }
                  }
                },
                [_vm._v("Cancel")]
              ),
              _c(
                "V3Button",
                {
                  staticClass: "save-button",
                  attrs: { disabled: !this.canSave },
                  on: { click: _vm.saveSnapshot }
                },
                [_vm._v("Save")]
              )
            ],
            1
          )
        ],
        2
      ),
      _c(
        "svg",
        {
          attrs: {
            width: "512",
            height: "512",
            viewBox: "0 0 1024 1024",
            id: "utility-scenario-minimap"
          },
          on: {
            mousedown: _vm.handleMouseDown,
            mouseup: _vm.handleMouseUp,
            mousemove: _vm.handleMouseMove,
            mouseleave: _vm.handleMouseUp
          }
        },
        [
          _c("image", {
            attrs: {
              x: "0",
              y: "0",
              width: "1024",
              height: "1024",
              href: _vm.data.map.display_icon_url,
              transform: "rotate(" + -_vm.data.map.rotate_deg + " 512 512)"
            }
          }),
          this.elements.length !== 0
            ? _c("g", [
                _c("rect", {
                  attrs: {
                    x: this.elements[0].x,
                    y: this.elements[0].y,
                    width: this.elements[0].width,
                    height: this.elements[0].height,
                    stroke: "red",
                    "stroke-width": "4",
                    fill: "none"
                  }
                })
              ])
            : _vm._e()
        ]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }