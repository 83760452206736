var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", { staticClass: "background" }, [
    _c("circle", { attrs: { r: "1e10", fill: "black" } }),
    _c("image", {
      attrs: {
        x: "0",
        y: "0",
        width: "1024",
        height: "1024",
        href: _vm.mapData.display_icon_url
      }
    }),
    _c("rect", {
      attrs: {
        x: "0",
        y: "0",
        width: "1024",
        height: "1024",
        fill: "black",
        opacity: "0.8"
      }
    })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }