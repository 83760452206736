var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.data
    ? _c(
        "div",
        { staticClass: "mh-container", class: { loading: _vm.loading } },
        [
          _vm.data && _vm.data.map
            ? _c("MapCell", {
                staticClass: "mh-map",
                attrs: {
                  border: false,
                  id: _vm.data.map.id,
                  size: "lg",
                  variant: "row"
                }
              })
            : _vm._e(),
          _vm.singleMatch && _vm.winnerTeam && _vm.loserTeam
            ? _c("div", { staticClass: "mh-center" }, [
                _c(
                  "div",
                  { staticClass: "mh-team left" },
                  [
                    _vm.winnerTeam.image
                      ? _c("V3TeamLogo", {
                          attrs: { src: _vm.winnerTeam.image, size: "lg" }
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "mh-team-name" }, [
                      _vm._v(_vm._s(_vm.winnerTeam.abbr || _vm.winnerTeam.name))
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "mh-score" }, [
                  _vm.winnerTeamWin != null
                    ? _c("div", { staticClass: "mh-score-win" }, [
                        _vm._v(_vm._s(_vm.winnerTeamWin ? "win" : "lose"))
                      ])
                    : _vm._e(),
                  _c(
                    "div",
                    { staticClass: "mh-score-win2" },
                    [_c("IconMapHeaderRight", { class: _vm.winnerGrid })],
                    1
                  ),
                  _c("div", { staticClass: "mh-score-winner" }, [
                    _vm._v(
                      _vm._s((_vm.winnerTeam && _vm.winnerTeam.score) || "-")
                    )
                  ]),
                  _c("div", { staticClass: "mh-score-sep" }, [_vm._v("/")]),
                  _c("div", { staticClass: "mh-score-loser" }, [
                    _vm._v(
                      _vm._s((_vm.loserTeam && _vm.loserTeam.score) || "-")
                    )
                  ]),
                  _c(
                    "div",
                    { staticClass: "mh-score-lose2" },
                    [_c("IconMapHeaderLeft", { class: _vm.loserGrid })],
                    1
                  ),
                  _vm.loserTeamLose != null
                    ? _c("div", { staticClass: "mh-score-lose" }, [
                        _vm._v(_vm._s(_vm.loserTeamLose ? "lose" : "win"))
                      ])
                    : _vm._e()
                ]),
                _c(
                  "div",
                  { staticClass: "mh-team right" },
                  [
                    _c("span", { staticClass: "mh-team-name" }, [
                      _vm._v(_vm._s(_vm.loserTeam.abbr || _vm.loserTeam.name))
                    ]),
                    _vm.loserTeam.image
                      ? _c("V3TeamLogo", {
                          attrs: {
                            src: _vm.loserTeam.image,
                            size: "lg",
                            dir: "left"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              ])
            : _vm._e(),
          !_vm.singleMatch && _vm.selectedTeam
            ? _c("div", { staticClass: "mh-center" }, [
                _c(
                  "div",
                  { staticClass: "mh-team left" },
                  [
                    _vm.selectedTeam.image
                      ? _c("V3TeamLogo", {
                          attrs: { src: _vm.selectedTeam.image, size: "lg" }
                        })
                      : _vm._e(),
                    _c("span", { staticClass: "mh-team-name" }, [
                      _vm._v(
                        _vm._s(_vm.selectedTeam.abbr || _vm.selectedTeam.name)
                      )
                    ])
                  ],
                  1
                ),
                _c("div", { staticClass: "mh-score" }, [
                  _c(
                    "div",
                    { staticClass: "mh-score-sel2" },
                    [
                      _c("IconMapHeaderRight", {
                        class: _vm.state.filters.round.teamRole
                      })
                    ],
                    1
                  )
                ])
              ])
            : _vm._e(),
          _vm.commonEvent
            ? _c(
                "div",
                { staticClass: "mh-event" },
                [
                  _c("div", { staticClass: "mh-event-name-container" }, [
                    _c("div", { staticClass: "mh-event-name" }, [
                      _vm._v(" " + _vm._s(_vm.commonEvent.name) + " ")
                    ])
                  ]),
                  _vm.commonEvent.logo_url
                    ? _c("ImgCell", {
                        attrs: {
                          border: false,
                          variant: "icon",
                          image: _vm.commonEvent.logo_url,
                          size: "lg",
                          transparent: ""
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }