var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mf-container" },
    _vm._l(_vm.normalizedMatches, function(match) {
      return _c(
        "V3Button",
        {
          key: match.id,
          staticClass: "mf-row",
          class: {
            selected: _vm.state.filters.base.matches.includes(match.id)
          },
          on: {
            click: function($event) {
              return _vm.toggleMatch(match.id)
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "header" },
            [
              match.event
                ? _c("div", { staticClass: "mf-row-event" }, [
                    _vm._v(" " + _vm._s(match.event.name) + " ")
                  ])
                : _c("div", { staticClass: "mf-row-date" }, [
                    _c(
                      "time",
                      {
                        attrs: { datetime: _vm._f("isoString")(match.created) }
                      },
                      [
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("localDate")(match.created)))
                        ]),
                        _c("span", [
                          _vm._v(_vm._s(_vm._f("localTime")(match.created)))
                        ])
                      ]
                    )
                  ]),
              match.winnerTeam.image
                ? _c("V3Image", {
                    staticClass: "mf-row-team-logo",
                    attrs: { src: match.winnerTeam.image, outline: true }
                  })
                : _c("div", { staticClass: "mf-row-team-name" }, [
                    _vm._v(
                      " " +
                        _vm._s(match.winnerTeam.abbr || match.winnerTeam.name) +
                        " "
                    )
                  ]),
              _c("div", { staticClass: "mf-row-score" }, [
                _c(
                  "span",
                  {
                    staticClass: "mf-row-score-winner",
                    class: match.winnerTeam.grid.toLowerCase()
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          match.winnerTeam ? match.winnerTeam.score : "-"
                        ) +
                        " "
                    )
                  ]
                ),
                _c("span", { staticClass: "mf-row-score-sep" }, [_vm._v("/")]),
                _c(
                  "span",
                  {
                    staticClass: "mf-row-score-loser",
                    class: match.loserTeam.grid.toLowerCase()
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(match.loserTeam ? match.loserTeam.score : "-") +
                        " "
                    )
                  ]
                )
              ]),
              match.loserTeam.image
                ? _c("V3Image", {
                    staticClass: "mf-row-team-logo",
                    attrs: { src: match.loserTeam.image, outline: true }
                  })
                : _c("div", { staticClass: "mf-row-team-name" }, [
                    _vm._v(
                      " " +
                        _vm._s(match.loserTeam.abbr || match.loserTeam.name) +
                        " "
                    )
                  ])
            ],
            1
          ),
          _c("div", { staticClass: "compositions" }, [
            _c(
              "div",
              { staticClass: "compositions-row" },
              [
                match.winnerTeam.image
                  ? _c("V3Image", {
                      staticClass: "mf-row-team-logo compositions-row-team",
                      attrs: { src: match.winnerTeam.image, outline: true }
                    })
                  : _c(
                      "div",
                      { staticClass: "mf-row-team-name compositions-row-team" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              match.winnerTeam.abbr || match.winnerTeam.name
                            ) +
                            " "
                        )
                      ]
                    ),
                _vm._l(match.winnerTeam.composition, function(teamAgent) {
                  return [
                    _c(
                      "div",
                      {
                        key: teamAgent.player.id,
                        staticClass: "compositions-row-player"
                      },
                      [
                        _c("V3Agent", {
                          staticClass: "compositions-row-player-agent",
                          attrs: { id: teamAgent.agent.id }
                        })
                      ],
                      1
                    )
                  ]
                })
              ],
              2
            ),
            _c(
              "div",
              { staticClass: "compositions-row" },
              [
                match.loserTeam.image
                  ? _c("V3Image", {
                      staticClass: "mf-row-team-logo compositions-row-team",
                      attrs: { src: match.loserTeam.image, outline: true }
                    })
                  : _c(
                      "div",
                      { staticClass: "mf-row-team-name compositions-row-team" },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              match.loserTeam.abbr || match.loserTeam.name
                            ) +
                            " "
                        )
                      ]
                    ),
                _vm._l(match.loserTeam.composition, function(teamAgent) {
                  return [
                    _c(
                      "div",
                      {
                        key: teamAgent.player.id,
                        staticClass: "compositions-row-player"
                      },
                      [
                        _c("V3Agent", {
                          staticClass: "compositions-row-player-agent",
                          attrs: { id: teamAgent.agent.id }
                        })
                      ],
                      1
                    )
                  ]
                })
              ],
              2
            )
          ])
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }