var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "V3MapFilter",
    {
      attrs: { active: _vm.value !== null, label: "Trades" },
      on: {
        click: function($event) {
          return _vm.$emit("change", null)
        }
      }
    },
    [
      _c(
        "V3Button",
        {
          attrs: { active: ["killer", "both"].includes(_vm.value) },
          on: {
            click: function($event) {
              return _vm.toggle("killer")
            }
          }
        },
        [
          _c("div", { staticClass: "team-name" }, [
            _vm._v(_vm._s(_vm.selectedTeam.abbr || _vm.selectedTeam.name))
          ])
        ]
      ),
      _c(
        "V3Button",
        {
          attrs: { active: ["victim", "both"].includes(_vm.value) },
          on: {
            click: function($event) {
              return _vm.toggle("victim")
            }
          }
        },
        [
          _c("div", { staticClass: "team-name" }, [
            _vm._v(
              _vm._s(
                _vm.opponentTeam
                  ? _vm.opponentTeam.abbr || _vm.opponentTeam.name
                  : "Opponent"
              )
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }