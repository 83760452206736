var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        height: "1em",
        viewBox: "0 0 512 512",
        width: "1em",
        xmlns: "http://www.w3.org/2000/svg",
        fill: "currentColor"
      }
    },
    [
      _c("path", {
        attrs: {
          d:
            "m466 43.2h-420a46.052 46.052 0 0 0 -46 46v256.8a46.053 46.053 0 0 0 46 46h420a46.053 46.053 0 0 0 46-46v-256.8a46.052 46.052 0 0 0 -46-46zm-334.158 316.8a128.128 128.128 0 0 1 50.54-74.836 130.63 130.63 0 0 0 147.238 0 128.126 128.126 0 0 1 50.538 74.836zm348.158-14a14.015 14.015 0 0 1 -14 14h-53.142a160.141 160.141 0 0 0 -75.769-108.1 16 16 0 0 0 -18.332 1.438 98.62 98.62 0 0 1 -125.511 0 16.005 16.005 0 0 0 -18.332-1.438 160.144 160.144 0 0 0 -75.772 108.1h-53.142a14.015 14.015 0 0 1 -14-14v-256.8a14.015 14.015 0 0 1 14-14h420a14.015 14.015 0 0 1 14 14zm-78.4 106.8a16 16 0 0 1 -16 16h-259.2a16 16 0 0 1 0-32h259.2a16 16 0 0 1 16 16zm-145.6-356.231a80.708 80.708 0 1 0 80.708 80.708 80.8 80.8 0 0 0 -80.708-80.708zm0 129.416a48.708 48.708 0 1 1 48.708-48.708 48.763 48.763 0 0 1 -48.708 48.708z"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }