var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ado-container" },
    [
      _c(
        "V3Button",
        {
          staticClass: "ado-player",
          class: {
            active:
              _vm.filters.display.players &&
              _vm.filters.display.players[_vm.player.id]
          },
          attrs: { disabled: _vm.disabled },
          on: {
            click: function($event) {
              return _vm.togglePlayer()
            }
          }
        },
        [
          _c("V3Agent", {
            staticClass: "ado-player-agent",
            attrs: {
              id: _vm.agent.id,
              grayscale: !(
                _vm.filters.display.players &&
                _vm.filters.display.players[_vm.player.id]
              )
            }
          }),
          _c("div", { staticClass: "ado-player-name" }, [
            _vm._v(_vm._s(_vm.player.name))
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ado-ability-container" },
        _vm._l(_vm.abilities, function(ability) {
          return _c(
            "V3Button",
            {
              key: ability.slot,
              staticClass: "ado-ability",
              attrs: { disabled: _vm.disabled },
              on: {
                click: function($event) {
                  return _vm.toggleAbility(ability)
                }
              }
            },
            [
              _c("V3Image", {
                staticClass: "ado-ability-image",
                class: [
                  ability.slot.toLowerCase(),
                  {
                    active: _vm.isAbilitySelected(ability)
                  }
                ],
                attrs: {
                  alt: ability.displayName,
                  gray: !_vm.isAbilitySelected(ability),
                  src: ability.displayIcon,
                  title: ability.displayName
                }
              })
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }