var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(_vm.$options.components.MapImageEntry, {
    tag: "component",
    attrs: {
      rotate: _vm.props.data.map.rotate_deg,
      x: _vm.props.event.location.x,
      y: _vm.props.event.location.y,
      "image-url": "/images/spike/spike-minimap2.png"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }