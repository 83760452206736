var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "hint" }, [_vm._v("Hold ALT key to draw.")]),
    _c(
      "div",
      { staticClass: "tools-container" },
      [
        _c(
          "V3Button",
          {
            attrs: { active: _vm.value.tool === "pencil" },
            on: {
              click: function($event) {
                return _vm.update({ tool: "pencil" })
              }
            }
          },
          [_c("IconPencil")],
          1
        ),
        _c(
          "V3Button",
          {
            attrs: { active: _vm.value.tool === "arrow" },
            on: {
              click: function($event) {
                return _vm.update({ tool: "arrow" })
              }
            }
          },
          [_c("IconUpArrow")],
          1
        ),
        _c(
          "V3Button",
          {
            attrs: { active: _vm.value.tool === "circle" },
            on: {
              click: function($event) {
                return _vm.update({ tool: "circle" })
              }
            }
          },
          [_c("IconEllipse")],
          1
        ),
        _c(
          "V3Button",
          {
            attrs: { active: _vm.value.tool === "text" },
            on: {
              click: function($event) {
                return _vm.update({ tool: "text" })
              }
            }
          },
          [_c("IconText")],
          1
        ),
        _c(
          "V3Button",
          {
            attrs: { active: _vm.value.tool === "line" },
            on: {
              click: function($event) {
                return _vm.update({ tool: "line" })
              }
            }
          },
          [_c("IconLine")],
          1
        ),
        _c(
          "V3Button",
          {
            attrs: { active: _vm.value.tool === "eraser" },
            on: {
              click: function($event) {
                return _vm.update({ tool: "eraser" })
              }
            }
          },
          [_c("IconEraser")],
          1
        )
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "dc-container" },
      [
        _vm._l(_vm.widths, function(width, widthIdx) {
          return _vm._l(_vm.colors, function(color, colorIdx) {
            return _c(
              "V3Button",
              {
                key: color + "-" + width,
                attrs: {
                  active:
                    _vm.value.color === colorIdx && _vm.value.width === widthIdx
                },
                on: {
                  click: function($event) {
                    return _vm.update({ color: colorIdx, width: widthIdx })
                  }
                }
              },
              [
                _c("span", {
                  staticClass: "indicator",
                  class: [
                    "color-" +
                      colorIdx +
                      " opacity-" +
                      _vm.value.opacity +
                      " width-" +
                      widthIdx,
                    {
                      active:
                        _vm.value.color === colorIdx &&
                        _vm.value.width === widthIdx
                    }
                  ]
                }),
                _c("span", {
                  staticClass: "line",
                  class: [
                    "color-" +
                      colorIdx +
                      " opacity-" +
                      _vm.value.opacity +
                      " width-" +
                      widthIdx,
                    {
                      active:
                        _vm.value.color === colorIdx &&
                        _vm.value.width === widthIdx
                    }
                  ]
                })
              ]
            )
          })
        }),
        _c(
          "div",
          { staticClass: "dc-btn-group" },
          [
            _c(
              "V3Button",
              {
                on: {
                  click: function($event) {
                    return _vm.$emit("clear")
                  }
                }
              },
              [_c("IconClear"), _vm._v(" Clear ")],
              1
            ),
            _c(
              "V3Button",
              {
                on: {
                  click: function($event) {
                    return _vm.$emit("undo")
                  }
                }
              },
              [_c("BIconArrowCounterclockwise"), _vm._v(" Undo ")],
              1
            )
          ],
          1
        )
      ],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }