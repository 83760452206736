var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.items.length
    ? _c(
        "ul",
        { staticClass: "legend-container" },
        _vm._l(_vm.items, function(item) {
          return _c(
            "li",
            {
              key: item.id,
              staticClass: "legend-item",
              style: { color: item.color }
            },
            [
              item.image
                ? _c("img", {
                    staticClass: "legend-img",
                    attrs: { src: item.image }
                  })
                : _vm._e(),
              _c("span", { staticClass: "legend-label" }, [
                _vm._v(_vm._s(item.label))
              ])
            ]
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }