var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mvo-container" },
    [
      _c(
        "V3Container",
        { attrs: { visible: _vm.expanded } },
        [
          _vm.mapMode === "replay2d" && _vm.vodPlayer === _vm.vodPlatform
            ? [
                _vm._l(_vm.teams, function(team) {
                  return [
                    _c(
                      "div",
                      {
                        key: "team-" + team.id,
                        staticClass: "mvo-team-container",
                        attrs: { title: team.name }
                      },
                      [
                        _c("V3TeamLogo", {
                          attrs: {
                            src: team.logo_url || team.image,
                            grayscale: ""
                          }
                        }),
                        _c("div", { staticClass: "mvo-team-name" }, [
                          _vm._v(_vm._s(team.name))
                        ])
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        key: "agents-" + team.id,
                        staticClass: "mvo-player-container"
                      },
                      [
                        _vm._l(team.agents, function(teamAgent) {
                          return [
                            _c(
                              "V3Button",
                              {
                                key: teamAgent.player.id,
                                staticClass: "mvo-player",
                                attrs: {
                                  disabled: "",
                                  title: teamAgent.player.name
                                }
                              },
                              [
                                _c("V3Agent", {
                                  staticClass: "mvo-player-agent",
                                  attrs: {
                                    id: teamAgent.agent.id,
                                    grayscale: ""
                                  }
                                }),
                                _c("div", { staticClass: "mvo-player-name" }, [
                                  _vm._v(_vm._s(teamAgent.player.name))
                                ])
                              ],
                              1
                            )
                          ]
                        })
                      ],
                      2
                    )
                  ]
                }),
                _c(
                  "V3BtnGroup",
                  [
                    _c(
                      "V3Button",
                      { attrs: { active: "", title: "Broadcast" } },
                      [_vm._v(" BRT ")]
                    ),
                    _c(
                      "V3Button",
                      { attrs: { disabled: "", title: "Spectator 1" } },
                      [_vm._v(" SPT1 ")]
                    ),
                    _c(
                      "V3Button",
                      { attrs: { disabled: "", title: "Spectator 2" } },
                      [_vm._v(" SPT2 ")]
                    ),
                    _c(
                      "V3Button",
                      { attrs: { disabled: "", title: "Spectator 3" } },
                      [_vm._v(" SPT3 ")]
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.mapMode === "replay2d" && _vm.vodPlayer === "playback"
            ? [
                _vm.mapSubMode === "agents"
                  ? [
                      _c(
                        "V3Panel",
                        { attrs: { expanded: "", title: "hide icons" } },
                        [
                          _c(
                            "V3BtnGroup",
                            [
                              _c(
                                "V3Button",
                                {
                                  attrs: {
                                    active: this.state.iconHide.unselected
                                  },
                                  on: {
                                    click: function() {
                                      return (this$1.state.iconHide.unselected = !this$1
                                        .state.iconHide.unselected)
                                    }
                                  }
                                },
                                [_vm._v(" Hide Unselected ")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  : _vm._e(),
                _vm._l(_vm.teams, function(team) {
                  return [
                    _c("V3Panel", {
                      key: "panel-" + team.id,
                      attrs: {
                        expandable: true,
                        "expand-on-title-click": false,
                        expanded: _vm.teamPanelExpanded[team.id]
                      },
                      on: {
                        "update:expanded": function($event) {
                          return _vm.$set(
                            _vm.teamPanelExpanded,
                            team.id,
                            $event
                          )
                        },
                        titleClick: function($event) {
                          return _vm.togglePlaybackTeamPlayers(team)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header-text",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mvo-team-name",
                                    class: { disabled: !team.enabled }
                                  },
                                  [_vm._v(_vm._s(team.name))]
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "header-icon",
                            fn: function() {
                              return [
                                _c("V3TeamLogo", {
                                  class: { disabled: !team.enabled },
                                  attrs: {
                                    src: team.logo_url || team.image,
                                    grayscale: ""
                                  }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c(
                                  "V3Container",
                                  {
                                    attrs: {
                                      visible: _vm.teamPanelExpanded[team.id]
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        key: "agents-" + team.id,
                                        staticClass: "agents-view-panel"
                                      },
                                      _vm._l(team.agents, function(teamAgent) {
                                        return _c("AgentReplayDisplayOptions", {
                                          key: teamAgent.player.id,
                                          attrs: {
                                            agent: teamAgent.agent,
                                            player: teamAgent.player,
                                            abilities: teamAgent.abilities,
                                            filters: _vm.filters,
                                            disabled: !team.enabled
                                          }
                                        })
                                      }),
                                      1
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }),
                _c(
                  "V3BtnGroup",
                  [
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapSubMode === "agents" },
                        on: {
                          click: function($event) {
                            _vm.mapSubMode = "agents"
                          }
                        }
                      },
                      [_vm._v(" Icons ")]
                    ),
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapSubMode === "heatmap" },
                        on: {
                          click: function($event) {
                            _vm.mapSubMode = "heatmap"
                          }
                        }
                      },
                      [_vm._v(" Heatmap ")]
                    ),
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapSubMode === "traces" },
                        on: {
                          click: function($event) {
                            _vm.mapSubMode = "traces"
                          }
                        }
                      },
                      [_vm._v(" Traces ")]
                    )
                  ],
                  1
                ),
                _c(
                  "V3BtnGroup",
                  [
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapOptions.includes("smokes") },
                        on: {
                          click: function($event) {
                            return _vm.toggleMapOptions("smokes")
                          }
                        }
                      },
                      [_vm._v(" Smokes ")]
                    ),
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapOptions.includes("walls") },
                        on: {
                          click: function($event) {
                            return _vm.toggleMapOptions("walls")
                          }
                        }
                      },
                      [_vm._v(" Walls ")]
                    ),
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapOptions.includes("deaths") },
                        on: {
                          click: function($event) {
                            return _vm.toggleMapOptions("deaths")
                          }
                        }
                      },
                      [_vm._v(" Deaths ")]
                    )
                  ],
                  1
                )
              ]
            : _vm._e(),
          _vm.mapMode === "analytics"
            ? [
                _vm._l(_vm.teams, function(team) {
                  return [
                    _c("V3Panel", {
                      key: "panel-" + team.id,
                      attrs: {
                        expandable: true,
                        "expand-on-title-click": false,
                        expanded: _vm.teamPanelExpanded[team.id]
                      },
                      on: {
                        "update:expanded": function($event) {
                          return _vm.$set(
                            _vm.teamPanelExpanded,
                            team.id,
                            $event
                          )
                        },
                        titleClick: function($event) {
                          return _vm.toggleAnalyticsTeamPlayers(team)
                        }
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header-text",
                            fn: function() {
                              return [
                                _c(
                                  "div",
                                  {
                                    staticClass: "mvo-team-name",
                                    class: { disabled: !team.enabled }
                                  },
                                  [_vm._v(_vm._s(team.name))]
                                )
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "header-icon",
                            fn: function() {
                              return [
                                _c("V3TeamLogo", {
                                  class: { disabled: !team.enabled },
                                  attrs: {
                                    src: team.logo_url || team.image,
                                    grayscale: ""
                                  }
                                })
                              ]
                            },
                            proxy: true
                          },
                          {
                            key: "default",
                            fn: function() {
                              return [
                                _c(
                                  "V3Container",
                                  {
                                    attrs: {
                                      visible: _vm.teamPanelExpanded[team.id]
                                    }
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        key: "agents-" + team.id,
                                        staticClass: "agents-view-panel"
                                      },
                                      _vm._l(team.agents, function(teamAgent) {
                                        return _c(
                                          "AgentAnalyticsDisplayOptions",
                                          {
                                            key: teamAgent.player.id,
                                            attrs: {
                                              agent: teamAgent.agent,
                                              player: teamAgent.player,
                                              abilities: teamAgent.abilities,
                                              filters: _vm.filters,
                                              disabled: !team.enabled
                                            }
                                          }
                                        )
                                      }),
                                      1
                                    )
                                  ]
                                )
                              ]
                            },
                            proxy: true
                          }
                        ],
                        null,
                        true
                      )
                    })
                  ]
                }),
                _vm.mapSubMode === "kdzones" ? _c("KDZoneFilter") : _vm._e(),
                _c(
                  "V3BtnGroup",
                  [
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapSubMode === "agents" },
                        on: {
                          click: function($event) {
                            _vm.mapSubMode = "agents"
                          }
                        }
                      },
                      [_vm._v(" Icons ")]
                    ),
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapSubMode === "heatmap" },
                        on: {
                          click: function($event) {
                            _vm.mapSubMode = "heatmap"
                          }
                        }
                      },
                      [_vm._v(" Heatmap ")]
                    ),
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapSubMode === "traces" },
                        on: {
                          click: function($event) {
                            _vm.mapSubMode = "traces"
                          }
                        }
                      },
                      [_vm._v(" Traces ")]
                    ),
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapSubMode === "kdzones" },
                        on: {
                          click: function($event) {
                            _vm.mapSubMode = "kdzones"
                          }
                        }
                      },
                      [_vm._v(" KD-Zone ")]
                    )
                  ],
                  1
                ),
                _c(
                  "V3BtnGroup",
                  [
                    _vm.mapSubMode === "heatmap"
                      ? _c(
                          "V3Button",
                          {
                            attrs: { active: this.state.minimapSelector },
                            on: {
                              click: function() {
                                return (this$1.state.minimapSelector = !this$1
                                  .state.minimapSelector)
                              }
                            }
                          },
                          [_vm._v(" Player Position % ")]
                        )
                      : _vm._e()
                  ],
                  1
                ),
                _c(
                  "V3BtnGroup",
                  [
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapOptions.includes("smokes") },
                        on: {
                          click: function($event) {
                            return _vm.toggleMapOptions("smokes")
                          }
                        }
                      },
                      [_vm._v(" Smokes ")]
                    ),
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapOptions.includes("walls") },
                        on: {
                          click: function($event) {
                            return _vm.toggleMapOptions("walls")
                          }
                        }
                      },
                      [_vm._v(" Walls ")]
                    ),
                    _c(
                      "V3Button",
                      {
                        attrs: { active: _vm.mapOptions.includes("deaths") },
                        on: {
                          click: function($event) {
                            return _vm.toggleMapOptions("deaths")
                          }
                        }
                      },
                      [_vm._v(" Deaths ")]
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      ),
      _vm.mapMode === "replay2d"
        ? _c("LeftSideTabs", {
            attrs: {
              "vod-platform": _vm.vodPlatform,
              "vod-player": _vm.vodPlayer,
              "has-playback": _vm.hasPlaybackPositions,
              "has-minimap": _vm.hasMinimapVods,
              "has-broadcast": _vm.hasBroadcastVod
            },
            on: {
              "update:vodPlayer": function($event) {
                _vm.vodPlayer = $event
              },
              "update:vod-player": function($event) {
                _vm.vodPlayer = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }