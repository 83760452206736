var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "tc-container",
      class: { "tc-container-presenter": _vm.exposed.presenterMode }
    },
    [
      !_vm.hideBrokenStuff && !_vm.exposed.state.presenterMode
        ? _c(
            "V3BtnGroup",
            { staticClass: "tc-align" },
            [
              _c(
                "V3Button",
                {
                  attrs: {
                    active: _vm.state.selected.alignRounds === "start",
                    title: "Align rounds by their start"
                  },
                  on: {
                    click: function($event) {
                      _vm.state.selected.alignRounds = "start"
                    }
                  }
                },
                [_c("IconAlignStart"), _vm._v(" Start ")],
                1
              ),
              _c(
                "V3Button",
                {
                  attrs: {
                    active: _vm.state.selected.alignRounds === "plant",
                    title: "Align rounds by plant time"
                  },
                  on: {
                    click: function($event) {
                      _vm.state.selected.alignRounds = "plant"
                    }
                  }
                },
                [
                  _c("img", {
                    attrs: { src: "/images/spike/spike-minimap.png" }
                  }),
                  _vm._v(" Plant ")
                ]
              ),
              _c(
                "V3Button",
                {
                  attrs: {
                    active: _vm.state.selected.alignRounds === "end",
                    title: "Align rounds by their end"
                  },
                  on: {
                    click: function($event) {
                      _vm.state.selected.alignRounds = "end"
                    }
                  }
                },
                [_c("IconAlignEnd"), _vm._v(" End ")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.exposed.state.mapMode === "replay2d" && !_vm.exposed.presenterMode
        ? _c(
            "V3BtnGroup",
            { staticClass: "tc-selection" },
            [
              _c(
                "V3Button",
                {
                  attrs: {
                    active: _vm.selected.time != null,
                    title: "Clear selection"
                  },
                  on: { click: _vm.clearSelection }
                },
                [_c("IconLinkInterrupt")],
                1
              ),
              _c(
                "V3Button",
                {
                  attrs: { title: "Set selection start" },
                  on: { click: _vm.setSelectionStart }
                },
                [_c("IconTextWrapOverflow")],
                1
              ),
              _c(
                "V3Button",
                {
                  attrs: { title: "Set selection end" },
                  on: { click: _vm.setSelectionEnd }
                },
                [_c("IconTextWrapOverflow", { staticClass: "t-sxm" })],
                1
              ),
              _c(
                "V3Button",
                {
                  attrs: {
                    title: "Toggle selection mode",
                    active: _vm.state.timeline.selectable
                  },
                  on: { click: _vm.toggleSelectable }
                },
                [_c("IconSelectTimeline")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.exposed.state.mapMode === "replay2d" && !_vm.exposed.presenterMode
        ? _c(
            "V3BtnGroup",
            {
              staticClass: "tc-marking",
              class: { invisible: !_vm.singleRound }
            },
            [
              _c(
                "V3Button",
                {
                  staticClass: "tc-marking-atk",
                  on: {
                    click: function($event) {
                      return _vm.addMark("atk")
                    }
                  }
                },
                [_c("IconBookmark"), _vm._v(" Mark ")],
                1
              ),
              _c(
                "V3Button",
                {
                  staticClass: "tc-marking-neutral",
                  on: {
                    click: function($event) {
                      return _vm.addMark(null)
                    }
                  }
                },
                [_c("IconBookmark"), _vm._v(" Mark ")],
                1
              ),
              _c(
                "V3Button",
                {
                  staticClass: "tc-marking-def",
                  on: {
                    click: function($event) {
                      return _vm.addMark("def")
                    }
                  }
                },
                [_c("IconBookmark"), _vm._v(" Mark ")],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "V3BtnGroup",
        { staticClass: "tc-zooming" },
        [
          _c(
            "V3Button",
            { attrs: { title: "Fit zoom" }, on: { click: _vm.zoomFit } },
            [_c("IconZoomFit")],
            1
          ),
          _c(
            "V3Button",
            { attrs: { title: "Zoom in" }, on: { click: _vm.zoomIn } },
            [_c("IconZoomIn")],
            1
          ),
          _c(
            "V3Button",
            { attrs: { title: "Zoom out" }, on: { click: _vm.zoomOut } },
            [_c("IconZoomOut")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }