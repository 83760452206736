var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tl-container", class: [_vm.dir, _vm.size] },
    [
      _c("V3Image", {
        staticClass: "tl-logo",
        attrs: {
          gray: _vm.gray,
          grayscale: _vm.grayscale,
          outline: !_vm.gray && !_vm.grayscale,
          src: _vm.src
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }