var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("line", {
    attrs: {
      x1: _vm.props.x1 * 1024,
      y1: _vm.props.y1 * 1024,
      x2: _vm.props.x2 * 1024,
      y2: _vm.props.y2 * 1024,
      stroke: _vm.props.color,
      "stroke-width": _vm.props.width
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }