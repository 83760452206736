var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 37.71 56.07",
        width: "38",
        height: "56"
      }
    },
    [
      _c("polygon", {
        staticClass: "cls-1",
        attrs: {
          points:
            "31.26 56.07 37.71 56.07 19.02 28.03 37.71 0 31.26 0 12.57 28.03 31.26 56.07"
        }
      }),
      _c("polygon", {
        staticClass: "cls-2",
        attrs: {
          points:
            "24.87 56.07 31.32 56.07 12.63 28.03 31.32 0 24.87 0 6.18 28.03 24.87 56.07"
        }
      }),
      _c("polygon", {
        staticClass: "cls-3",
        attrs: {
          points:
            "18.69 56.07 25.14 56.07 6.45 28.03 25.14 0 18.69 0 0 28.03 18.69 56.07"
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }