var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 512 512",
        fill: "currentColor"
      }
    },
    [
      _c("g", { attrs: { fill: "currentColor" } }, [
        _c("path", {
          attrs: {
            d:
              "M475.3,100.7l-64-64c-6.3-6.3-16.4-6.3-22.6,0l-64,64c-6.3,6.3-6.3,16.4,0,22.6s16.4,6.3,22.6,0L384,86.6V112\n\tc0,76.4-23,162.1-82.7,201.7C314.5,243.6,266.7,208,224,208c-44.1,0-80,35.9-80,80c0,40.3,30.4,84.3,96.5,79\n\tc-39.8,47.6-112.8,81-192.5,81c-8.8,0-16,7.2-16,16s7.2,16,16,16c106.3,0,199.7-51.6,237.7-122.5C380.5,323.4,416,210.9,416,112\n\tV86.6l36.7,36.7c6.3,6.3,16.4,6.3,22.6,0S481.6,106.9,475.3,100.7L475.3,100.7z M176,288c0-26.5,21.5-48,48-48\n\tc35.4,0,62.6,34.6,38.8,91C204.1,346.6,176,321.1,176,288z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }