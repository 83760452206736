var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ad-container" },
    [
      _c("div", { staticClass: "ad", attrs: { id: "video-ad" } }),
      !_vm.locked
        ? _c(
            "b-button",
            {
              staticClass: "btn-close",
              attrs: { variant: "primary" },
              on: { click: _vm.closeAd }
            },
            [_vm._v("Close ad")]
          )
        : _vm._e(),
      _vm.error
        ? _c("div", { staticClass: "error" }, [_vm._v("Cannot load ad")])
        : _vm._e(),
      _vm.loading
        ? _c("div", { staticClass: "loading" }, [_vm._v("Loading ad...")])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }