var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "0.9em",
        height: "0.9em",
        viewBox: "0 0 512 512",
        fill: "currentColor"
      }
    },
    [
      _c("g", { attrs: { fill: "currentColor" } }, [
        _c("path", {
          attrs: {
            d:
              "M497,0h-62c-8.284,0-15,6.716-15,15v55.787L68.787,422H15c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h60\n\t\t\tc8.284,0,15-6.716,15-15v-53.787L441.213,92H497c8.284,0,15-6.716,15-15V15C512,6.716,505.284,0,497,0z M60,482H30v-30h30V482z\n\t\t\t M482,62h-32V30h32V62z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }