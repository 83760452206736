var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "g",
    { staticClass: "kill", class: { highlight: _vm.props.highlight } },
    [
      _vm.props.event.killer.location != null &&
      _vm.props.event.victim.location != null
        ? _c(_vm.$options.components.MapLineEntry, {
            tag: "component",
            attrs: {
              color: _vm._f("roleColor")(
                _vm.props.data.roundTeams[_vm.props.event.killer.round_team_id]
                  .role,
                1
              ),
              x1: _vm.props.event.killer.location.x,
              y1: _vm.props.event.killer.location.y,
              x2: _vm.props.event.victim.location.x,
              y2: _vm.props.event.victim.location.y
            }
          })
        : _vm._e(),
      _vm.props.event.killer.location
        ? _c(
            "g",
            {
              attrs: {
                stroke: _vm._f("roleColor")(
                  _vm.props.highlight
                    ? _vm.props.data.roundTeams[
                        _vm.props.event.killer.round_team_id
                      ].role
                    : "",
                  1
                )
              }
            },
            [
              _c(_vm.$options.components.MapEventEntry, {
                tag: "component",
                attrs: { data: _vm.props.data, event: _vm.props.event.killer }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.props.event.victim.location
        ? _c(
            "g",
            {
              attrs: {
                stroke: _vm._f("roleColor")(
                  _vm.props.highlight
                    ? _vm.props.data.roundTeams[
                        _vm.props.event.victim.round_team_id
                      ].role
                    : "",
                  1
                )
              }
            },
            [
              _c(_vm.$options.components.MapEventEntry, {
                tag: "component",
                attrs: { data: _vm.props.data, event: _vm.props.event.victim }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }