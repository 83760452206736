var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "g",
    {
      attrs: {
        opacity: _vm.hidden ? 0 : _vm.faded ? _vm.$options.meta.fadeOpacity : 1
      }
    },
    [
      _c(_vm.$options.components.MapPoi, {
        tag: "component",
        attrs: {
          color: _vm._f("roleColor")(
            _vm.data.roundTeams[
              _vm.data.roundPlayers[_vm.event.round_player_id].round_team_id
            ].role
          ),
          direction: _vm.event.location.view_radians,
          rotate: _vm.data.map.rotate_deg,
          x: _vm.event.location.x,
          y: _vm.event.location.y
        }
      }),
      _c(_vm.$options.components.MapImageEntry, {
        tag: "component",
        attrs: {
          "image-url":
            _vm.data.agents[
              _vm.data.matchPlayers[_vm.event.match_player_id].agent_id
            ].display_icon_small_url,
          rotate: _vm.data.map.rotate_deg,
          x: _vm.event.location.x,
          y: _vm.event.location.y
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }