var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "g",
    {
      attrs: {
        transform:
          "translate(" + _vm.props.x * 1024 + "," + _vm.props.y * 1024 + ")"
      }
    },
    [
      _vm.props.direction != null
        ? _c(
            "g",
            {
              attrs: {
                transform: _vm._f("rot")(
                  _vm.$options.filters.radToDeg(_vm.props.direction) + 90
                )
              }
            },
            [
              _c("path", {
                attrs: {
                  fill: _vm.props.color,
                  d: _vm._f("dDirection")(_vm.props.size)
                }
              })
            ]
          )
        : _c("path", {
            attrs: {
              fill: _vm.props.color,
              d: _vm._f("dCircle")(_vm.props.size)
            }
          })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }