var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "left-side-tabs" },
    [
      _c(
        "V3Button",
        {
          attrs: {
            active: _vm.vodPlayer === _vm.vodPlatform,
            disabled: !_vm.hasBoradcast
          },
          on: {
            click: function($event) {
              return _vm.$emit("update:vodPlayer", _vm.vodPlatform)
            }
          }
        },
        [
          _c("IconBroadcast"),
          _c("div", { staticClass: "lst-label" }, [_vm._v("Broadcast")])
        ],
        1
      ),
      _c(
        "V3Button",
        {
          attrs: {
            active: _vm.vodPlayer === "playback",
            disabled: !_vm.hasPlayback
          },
          on: {
            click: function($event) {
              return _vm.$emit("update:vodPlayer", "playback")
            }
          }
        },
        [
          _c("Icon2D"),
          _c("div", { staticClass: "lst-label" }, [_vm._v("2D Map")])
        ],
        1
      ),
      _c(
        "V3Button",
        {
          attrs: { active: _vm.vodPlayer === "vod", disabled: !_vm.hasMinimap },
          on: {
            click: function($event) {
              return _vm.$emit("update:vodPlayer", "vod")
            }
          }
        },
        [
          _c("IconMinimap"),
          _c("div", { staticClass: "lst-label" }, [_vm._v("Minimap")])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }