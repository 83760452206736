var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 48 48"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            stroke: "currentColor",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "4"
          }
        },
        [
          _c("path", {
            attrs: {
              d:
                "M27 14H42C43.1046 14 44 14.8954 44 16V30C44 31.1046 43.1046 32 42 32H38"
            }
          }),
          _c("path", {
            attrs: {
              d:
                "M11 14H6C4.89543 14 4 14.8954 4 16V30C4 31.1046 4.89543 32 6 32H21"
            }
          }),
          _c("path", { attrs: { d: "M14 6L34 40" } }),
          _c("path", { attrs: { d: "M32 23H36" } }),
          _c("path", { attrs: { d: "M12 23H16" } })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }