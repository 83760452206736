var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "p-3 video-player-container" }, [
    _vm.vodStatus === "init"
      ? _c("div", { staticClass: "text-center" }, [_vm._v("Loading")])
      : _c(
          "div",
          { staticClass: "video-player-container" },
          [
            !_vm.roundReplayUrl
              ? _c(
                  "div",
                  { staticClass: "mt-2 mb-5" },
                  [
                    !_vm.vodStatus
                      ? _c("p", { staticClass: "text-center text-info" }, [
                          _vm._v(
                            " No video for this round. Please, use the form below to add one. "
                          )
                        ])
                      : _vm._e(),
                    _vm.vodStatus === "failed"
                      ? _c("p", { staticClass: "text-center text-info" }, [
                          _vm._v(
                            " Failed to upload the video. Please, try again. "
                          )
                        ])
                      : _vm._e(),
                    _vm.vodStatus === "provisioning"
                      ? _c("p", { staticClass: "text-center text-info" }, [
                          _vm._v("The video is being provisioned")
                        ])
                      : _vm._e(),
                    _vm.vodStatus === "executing"
                      ? _c("p", { staticClass: "text-center text-info" }, [
                          _vm._v("The video is being processed")
                        ])
                      : _vm._e(),
                    _vm.vodStatus === "partial"
                      ? _c("p", { staticClass: "text-center text-info" }, [
                          _vm._v("Match videos are partially available")
                        ])
                      : _vm._e(),
                    !_vm.vodStatus || _vm.vodStatus === "failed"
                      ? _c("SubmitMatchVod")
                      : _vm._e()
                  ],
                  1
                )
              : _c(
                  "VodReplay",
                  _vm._g(
                    {
                      ref: "vod",
                      attrs: {
                        "control-bar": _vm.controlBar,
                        "current-time": _vm.localCurrentTime,
                        playing: _vm.playing,
                        "playback-rate": _vm.playbackRate,
                        url: _vm.roundReplayUrl,
                        "mute-vod": _vm.muteVod
                      },
                      on: {
                        "update:currentTime": function($event) {
                          _vm.localCurrentTime = $event
                        },
                        "update:current-time": function($event) {
                          _vm.localCurrentTime = $event
                        }
                      }
                    },
                    _vm.listeners
                  )
                )
          ],
          1
        )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }