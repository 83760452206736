var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "map-modal" },
    [
      _c(
        "V3Button",
        {
          staticStyle: {
            color: "black",
            width: "60px",
            "background-color": "white"
          },
          on: {
            click: function($event) {
              return _vm.$emit("close")
            }
          }
        },
        [_vm._v(" Close ")]
      ),
      _c(
        "V3BtnGroup",
        [
          _c(
            "V3Button",
            {
              attrs: { active: _vm.modalMode === "overview" },
              on: {
                click: function($event) {
                  return _vm.updateModalMode("overview")
                }
              }
            },
            [_vm._v(" Round Overview ")]
          )
        ],
        1
      ),
      _vm.modalMode === "overview"
        ? _c(
            "div",
            { staticClass: "wrapper" },
            [
              _vm._m(0),
              _vm._l(_vm.roundOverview, function(round, idx) {
                return [
                  _c("div", { key: idx, staticClass: "overviewRow" }, [
                    _c("div", { staticClass: "roundNumber" }, [
                      _vm._v(" " + _vm._s(round.round_number) + " ")
                    ]),
                    _c("div", { staticClass: "teamGrid" }, [
                      _c(
                        "div",
                        { staticClass: "teamRole", class: [round.red_role] },
                        [
                          _c("p", { staticClass: "truncate" }, [
                            _vm._v(_vm._s(_vm.teams[round.red_team].name))
                          ])
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "teamRole", class: [round.blue_role] },
                        [
                          _c("p", { staticClass: "truncate" }, [
                            _vm._v(_vm._s(_vm.teams[round.blue_team].name))
                          ])
                        ]
                      )
                    ]),
                    _c("div", { staticClass: "teamGrid" }, [
                      _c(
                        "div",
                        {
                          staticClass: "score",
                          class: round.winner === "red" ? "winner" : ""
                        },
                        [_vm._v(" " + _vm._s(round.red_score) + " ")]
                      ),
                      _c(
                        "div",
                        {
                          staticClass: "score",
                          class: round.winner === "blue" ? "winner" : ""
                        },
                        [_vm._v(" " + _vm._s(round.blue_score) + " ")]
                      )
                    ]),
                    _c("div", { staticClass: "teamGrid" }, [
                      _c(
                        "div",
                        {
                          staticClass: "outcome",
                          class: [
                            "red" === round.winner
                              ? round.red_role + " redTeam"
                              : round.blue_role + " blueTeam"
                          ]
                        },
                        [
                          _c(_vm.icon(round.outcome), { tag: "component" }),
                          _vm._v(" " + _vm._s(round.plant) + " ")
                        ],
                        1
                      )
                    ]),
                    _c(
                      "div",
                      {
                        staticClass: "teamGrid",
                        staticStyle: { "justify-content": "start" }
                      },
                      [
                        _c("div", { staticClass: "econ" }, [
                          _vm._v(" " + _vm._s(round.red_econ) + " ")
                        ]),
                        _c("div", { staticClass: "econ" }, [
                          _vm._v(" " + _vm._s(round.blue_econ) + " ")
                        ])
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "teamGrid",
                        staticStyle: {
                          "justify-content": "start",
                          "row-gap": "4px"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "column-gap": "10px",
                              "flex-grow": "0"
                            }
                          },
                          [
                            _vm._l(round.end_state.red, function(
                              alive,
                              red_id
                            ) {
                              return _c(
                                "div",
                                {
                                  key: red_id,
                                  class: [round.ults.red[red_id] ? "ult" : ""]
                                },
                                [
                                  _c("img", {
                                    class: [alive ? round.red_role : "dead"],
                                    staticStyle: {
                                      width: "26px",
                                      height: "26px",
                                      "border-radius": "9999px",
                                      padding: "2px"
                                    },
                                    attrs: {
                                      src: _vm.players[red_id].agent_img,
                                      alt: _vm.players[red_id].agent_name
                                    }
                                  })
                                ]
                              )
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "0.55rem",
                                  "align-self": "end"
                                }
                              },
                              [_vm._v(_vm._s(round.end_count.red) + "x")]
                            )
                          ],
                          2
                        ),
                        _c(
                          "div",
                          {
                            staticStyle: {
                              display: "flex",
                              "flex-direction": "row",
                              "column-gap": "10px",
                              "flex-grow": "0"
                            }
                          },
                          [
                            _vm._l(round.end_state.blue, function(
                              alive,
                              blue_id
                            ) {
                              return _c(
                                "div",
                                {
                                  key: blue_id,
                                  class: [round.ults.blue[blue_id] ? "ult" : ""]
                                },
                                [
                                  _c("img", {
                                    class: [alive ? round.blue_role : "dead"],
                                    staticStyle: {
                                      width: "26px",
                                      height: "26px",
                                      "border-radius": "9999px",
                                      padding: "2px"
                                    },
                                    attrs: {
                                      src: _vm.players[blue_id].agent_img,
                                      alt: _vm.players[blue_id].agent_name
                                    }
                                  })
                                ]
                              )
                            }),
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  "font-size": "0.55rem",
                                  "align-self": "end"
                                }
                              },
                              [_vm._v(_vm._s(round.end_count.blue) + "x")]
                            )
                          ],
                          2
                        )
                      ]
                    )
                  ]),
                  idx === 11 || (idx === 23 && _vm.roundOverview.length > 24)
                    ? _c("hr", { key: idx + "break" })
                    : _vm._e()
                ]
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "tooltipmain" }, [
      _vm._v(" ? "),
      _c("span", { staticClass: "tooltiptext" }, [
        _vm._v(
          " Players alive at the end of the round are colored. Players whose Ultimates are ready at the start of the round will have black borders (if Ultimate Data is available). "
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }