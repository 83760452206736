var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 512 512",
        fill: "currentColor"
      }
    },
    [
      _c("g", { attrs: { fill: "currentColor" } }, [
        _c("path", {
          staticClass: "st0",
          attrs: {
            d:
              "M225.1,57.3c2.9-8.7,11.1-14.6,20.2-14.6h21.3c9.2,0,17.3,5.9,20.2,14.6l63.8,191.5c0.1,0.3,0.2,0.6,0.3,0.9\n\tl21.2,63.5c3.7,11.2-2.3,23.3-13.5,27c-11.2,3.7-23.3-2.3-27-13.5l-16.5-49.4H196.7l-16.5,49.4c-3.7,11.2-15.8,17.2-27,13.5\n\tc-11.2-3.7-17.2-15.8-13.5-27l21.2-63.5c0.1-0.3,0.2-0.6,0.3-0.9L225.1,57.3z M210.9,234.7h90.1L256,99.5L210.9,234.7z M21.3,426.7\n\tc0-11.8,9.6-21.3,21.3-21.3h426.7c11.8,0,21.3,9.6,21.3,21.3c0,11.8-9.6,21.3-21.3,21.3H42.7C30.9,448,21.3,438.4,21.3,426.7z"
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }