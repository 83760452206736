var render = function(_h, _vm) {
  var _c = _vm._c
  return _c("circle", {
    staticClass: "smoke",
    attrs: {
      opacity: _vm.props.event.conf != null ? _vm.props.event.conf : 1,
      cx: _vm.props.event.location.x * 1024,
      cy: _vm.props.event.location.y * 1024,
      r:
        _vm.props.event.radius < 1
          ? _vm.props.event.radius * 1024
          : _vm.props.event.radius
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }