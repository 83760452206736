var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        "dialog",
        { ref: "modalfilter" },
        [_c("MapModal", { on: { close: _vm.onModalClose } })],
        1
      ),
      _vm.renderUtilityModal
        ? _c(
            "dialog",
            { ref: "modalutilfilter" },
            [
              _c("MapUtilityModal", {
                on: {
                  close: function($event) {
                    return _vm.onModalClose("utility")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.singleMatch
        ? _c(
            "dialog",
            { ref: "modaloverview" },
            [
              _c("OverviewModal", {
                on: {
                  close: function($event) {
                    return _vm.onModalClose("overview")
                  }
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c(
        "dialog",
        {
          ref: "modalboard",
          staticStyle: { height: "100%", width: "100%" },
          on: {
            cancel: function($event) {
              $event.preventDefault()
            }
          }
        },
        [
          _c("DrawingBoardModal", {
            ref: "drawingboard",
            on: {
              close: function($event) {
                return _vm.onModalClose("board")
              }
            }
          })
        ],
        1
      ),
      _c(
        "MapPortal",
        {
          staticClass:
            "d-flex flex-fill align-items-stretch align-self-stretch",
          attrs: { to: "header-right" }
        },
        [
          _c(
            "MainMapModeButton",
            {
              attrs: { active: _vm.mapMode === "replay2d" },
              on: {
                click: function($event) {
                  _vm.mapMode = "replay2d"
                }
              }
            },
            [_c("BIconYoutube"), _vm._v(" Replay mode ")],
            1
          ),
          _c(
            "MainMapModeButton",
            {
              attrs: {
                active: _vm.mapMode === "analytics",
                disabled: !_vm.mapSettings.analyticsEnabled
              },
              on: {
                click: function($event) {
                  _vm.mapMode = "analytics"
                }
              }
            },
            [_c("BIconFileBarGraphFill"), _vm._v(" Analytics mode ")],
            1
          ),
          _c(
            "MainMapModeButton",
            {
              on: {
                click: function($event) {
                  return _vm.onModalOpen("board")
                }
              }
            },
            [_c("BIconBrush"), _vm._v(" Board ")],
            1
          )
        ],
        1
      ),
      _c(
        "MapPortal",
        { staticClass: "h-100", attrs: { to: "sidebar-left" } },
        [
          _c(
            "LeftSideBar",
            {
              staticClass: "menu",
              attrs: {
                bookmarks: _vm.notesForTimeline,
                "note-tags": _vm.noteTags,
                "saving-bookmark": _vm.savingBookmark,
                "vod-platform": _vm.vodPlatform,
                "has-playback-positions": _vm.hasPlaybackPositions,
                "has-minimap-vods": _vm.hasMinimapVods,
                "has-broadcast-vod": _vm.hasVod
              },
              on: { saveNote: _vm.saveNote, selectBookmark: _vm.loadBookmark }
            },
            [
              _vm.singleMatch
                ? _c(
                    "V3Button",
                    {
                      staticClass: "overview",
                      on: {
                        click: function($event) {
                          return _vm.onModalOpen("overview")
                        }
                      }
                    },
                    [_vm._v(" Match Overview ")]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c("div", { staticClass: "main-container" }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "drag",
                rawName: "v-drag",
                value: _vm.dragHandler,
                expression: "dragHandler"
              },
              {
                name: "pinch",
                rawName: "v-pinch",
                value: _vm.pinchHandler,
                expression: "pinchHandler"
              }
            ],
            staticClass: "main-content"
          },
          [
            false
              ? _c("div", { staticClass: "toolbox top" }, [
                  _c(
                    "div",
                    { staticClass: "float-right box" },
                    [
                      this.exposed.mapSettings.reportsEnabled &&
                      _vm.savingReport
                        ? _c(
                            "b-form",
                            {
                              attrs: { inline: "" },
                              on: {
                                submit: function($event) {
                                  $event.preventDefault()
                                  return _vm.saveReport.apply(null, arguments)
                                }
                              }
                            },
                            [
                              _c(
                                "b-input-group",
                                { attrs: { prepend: "Report name" } },
                                [
                                  _c("b-form-input", {
                                    attrs: { autofocus: "", required: "" },
                                    model: {
                                      value: _vm.reportName,
                                      callback: function($$v) {
                                        _vm.reportName = $$v
                                      },
                                      expression: "reportName"
                                    }
                                  }),
                                  _c(
                                    "b-input-group-append",
                                    [
                                      _c(
                                        "b-button",
                                        {
                                          attrs: {
                                            type: "submit",
                                            disabled: !_vm.reportName
                                          }
                                        },
                                        [_c("b-icon-cloud-plus")],
                                        1
                                      ),
                                      _c(
                                        "b-button",
                                        {
                                          on: {
                                            click: function($event) {
                                              _vm.savingReport = false
                                            }
                                          }
                                        },
                                        [_c("b-icon-x")],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      false
                        ? _c(
                            "b-button-group",
                            { staticClass: "align-items-stretch" },
                            [
                              this.exposed.mapSettings.reportsEnabled
                                ? _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          _vm.savingReport = true
                                        }
                                      }
                                    },
                                    [_c("b-icon-cloud-plus")],
                                    1
                                  )
                                : _vm._e(),
                              _vm.mapMode !== "replay2d"
                                ? _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadImage("svg")
                                        }
                                      }
                                    },
                                    [_c("IconFiletypeSvg")],
                                    1
                                  )
                                : _vm._e(),
                              _vm.mapMode !== "replay2d"
                                ? _c(
                                    "b-button",
                                    {
                                      on: {
                                        click: function($event) {
                                          return _vm.downloadImage("png")
                                        }
                                      }
                                    },
                                    [_c("IconFiletypePng")],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _c("div", { staticClass: "toolbox bottom" }, [
              _c(
                "div",
                { staticClass: "float-right box dbg" },
                [
                  _vm.mapSubMode && _vm.mapSubMode.startsWith("traces")
                    ? _c("MapLegend", {
                        attrs: { data: _vm.data, legend: _vm.sub.legend }
                      })
                    : _vm._e()
                ],
                1
              )
            ]),
            true
              ? _c("div", { staticClass: "toolbox bottom" }, [
                  _c("div", { staticClass: "float-left box dbg" }, [
                    _c("ul", { staticClass: "info" }, [
                      _c("li", [
                        _vm._v(
                          _vm._s(_vm.data.teams[_vm.selected.team].name) +
                            " - " +
                            _vm._s(
                              _vm.state.filters.round.teamRole.toUpperCase()
                            )
                        )
                      ])
                    ]),
                    _c("ul", { staticClass: "info" }, [
                      _c("li", [
                        _vm._v(
                          "Rounds: " +
                            _vm._s(
                              Object.keys(_vm.data.rounds).filter(function(id) {
                                return _vm.filters.round(id)
                              }).length
                            )
                        )
                      ])
                    ]),
                    _c("ul", { staticClass: "info" }, [
                      _c("li", [
                        _vm._v(
                          " Wins: " +
                            _vm._s(
                              Object.values(_vm.data.rounds).filter(function(
                                r
                              ) {
                                return (
                                  _vm.filters.round(r.id) &&
                                  r.round_team_winner === _vm.selected.team
                                )
                              }).length
                            ) +
                            " "
                        )
                      ])
                    ]),
                    _vm.mapMode === "analytics" && _vm.mapSubMode !== "kdzones"
                      ? _c("details", [
                          _c("summary", [
                            _c("span", [
                              _vm._v(
                                _vm._s(_vm.eventsForMap.length) +
                                  " positions/utilities"
                              )
                            ])
                          ]),
                          _c("ul", { staticClass: "info-and-list" }, [
                            this.state.filters.display.players != null
                              ? _c("li", [
                                  _c(
                                    "summary",
                                    { staticClass: "info-or-list" },
                                    _vm._l(
                                      this.state.filters.display.players,
                                      function(v, k) {
                                        return _c("li", { key: k }, [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.data.players[k].name) +
                                              ": " +
                                              _vm._s(
                                                _vm.eventsForMap.filter(
                                                  function(ev) {
                                                    return (
                                                      _vm.data.players[k].id ===
                                                      _vm.data.matchPlayers[
                                                        ev.match_player_id
                                                      ].player_id
                                                    )
                                                  }
                                                ).length
                                              ) +
                                              " "
                                          )
                                        ])
                                      }
                                    ),
                                    0
                                  )
                                ])
                              : _vm._e()
                          ])
                        ])
                      : _vm._e()
                  ])
                ])
              : _vm._e(),
            _c("div", { staticClass: "map-content" }, [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value:
                        _vm.mapMode === "replay2d" &&
                        _vm.vodPlayer !== "playback",
                      expression:
                        "mapMode === 'replay2d' && vodPlayer !== 'playback'"
                    }
                  ]
                },
                [_vm._t("replay2d")],
                2
              ),
              _vm.mapMode !== "replay2d" ||
              (_vm.vodPlayer !== "twitch" && _vm.vodPlayer !== "youtube")
                ? _c(
                    "svg",
                    {
                      ref: "svg_root",
                      staticClass: "svg-root",
                      attrs: {
                        viewBox: "0 0 1024 1024",
                        preserveAspectRatio: "xMidYMid meet",
                        xmlns: "http://www.w3.org/2000/svg",
                        "xmlns:svg": "http://www.w3.org/2000/svg"
                      }
                    },
                    [
                      _c("svg:style", [
                        _vm._v(
                          " .smoke { fill: rgba(200, 200, 200, 0.25); stroke: rgba(200, 200, 200, 0.75); } .map-spike { filter: invert(52%) sepia(41%) saturate(6182%) hue-rotate(338deg) brightness(100%) contrast(91%); } .highlight { stroke-width: 3px; } "
                        )
                      ]),
                      _vm.screenshot
                        ? _c("image", {
                            attrs: {
                              x: "0",
                              y: "0",
                              width: "1024",
                              height: "1024",
                              href: _vm.screenshot
                            }
                          })
                        : _vm._e(),
                      _c("MapImageEntry", {
                        staticClass: "img-only",
                        attrs: {
                          "image-url":
                            "/images/AugmentLogo_Horizontal_black_backgr.svg",
                          x: -80 / 1024,
                          y: 980 / 1024,
                          size: 200 / 1024
                        }
                      }),
                      _vm.mapMode !== "replay2d" || _vm.vodPlayer === "playback"
                        ? _c(
                            "g",
                            {
                              attrs: {
                                transform:
                                  "rotate(" +
                                  -_vm.data.map.rotate_deg +
                                  " 512 512)"
                              }
                            },
                            [
                              _c("MapBackground", {
                                key: "map-bg",
                                attrs: { "map-data": _vm.data.map }
                              }),
                              _vm.hasSmokes && _vm.mapOptions.includes("smokes")
                                ? _c("MapEvents", {
                                    attrs: {
                                      "current-time":
                                        _vm.mapMode === "replay2d"
                                          ? _vm.currentTime
                                          : null,
                                      data: _vm.data,
                                      events: _vm.smokes,
                                      "filter-current-time":
                                        _vm.filterCurrentTime,
                                      selected: _vm.selected,
                                      variant: "smokes"
                                    }
                                  })
                                : _vm._e(),
                              _vm.hasWalls && _vm.mapOptions.includes("walls")
                                ? _c("MapEvents", {
                                    attrs: {
                                      "current-time":
                                        _vm.mapMode === "replay2d"
                                          ? _vm.currentTime
                                          : null,
                                      data: _vm.data,
                                      events: _vm.walls,
                                      "filter-current-time":
                                        _vm.filterCurrentTime,
                                      selected: _vm.selected,
                                      variant: "walls"
                                    }
                                  })
                                : _vm._e(),
                              _vm.mapOptions.includes("deaths") &&
                              _vm.mapSubMode !== "kdzones"
                                ? _c("MapEvents", {
                                    attrs: {
                                      data: _vm.data,
                                      events:
                                        _vm.mapMode === "replay2d"
                                          ? _vm.deathsForReplay
                                          : _vm.deathsForMap,
                                      variant: "deaths"
                                    }
                                  })
                                : _vm._e(),
                              _vm.mapSubMode === "agents" && _vm.canRender(2500)
                                ? _c("MapEvents", {
                                    attrs: {
                                      "current-time":
                                        _vm.mapMode === "replay2d"
                                          ? _vm.currentTime
                                          : null,
                                      data: _vm.data,
                                      events: _vm.eventsForMap,
                                      "filter-current-time":
                                        _vm.filterCurrentTime,
                                      "highlight-event":
                                        _vm.hoveredTimelineEvent,
                                      "highlighted-gids":
                                        _vm.state.filters.display.gids,
                                      selected: _vm.selected,
                                      variant: "positions",
                                      "icon-hide": this.state.iconHide
                                    },
                                    on: { "update:legend": _vm.updateLegend }
                                  })
                                : _vm._e(),
                              _vm.mapSubMode === "heatmap" &&
                              _vm.canRender(250000)
                                ? _c("HeatmapEvents", {
                                    attrs: {
                                      "current-time":
                                        _vm.mapMode === "replay2d"
                                          ? _vm.currentTime
                                          : null,
                                      data: _vm.data,
                                      events:
                                        _vm.events &&
                                        (_vm.mapMode === "replay2d"
                                          ? _vm.hasAdvanced
                                            ? _vm.eventsForPlayback
                                            : _vm.events.positions
                                          : _vm.eventsForMap),
                                      "filter-current-time":
                                        _vm.filterCurrentTime,
                                      "highlighted-gids":
                                        _vm.state.filters.display.gids
                                    },
                                    on: { "update:legend": _vm.updateLegend }
                                  })
                                : _vm._e(),
                              _vm.mapSubMode === "traces" &&
                              _vm.canRender(250000)
                                ? _c("TraceMap", {
                                    attrs: {
                                      "current-time":
                                        _vm.mapMode === "replay2d"
                                          ? _vm.currentTime
                                          : null,
                                      data: _vm.data,
                                      events:
                                        _vm.events &&
                                        (_vm.mapMode === "replay2d"
                                          ? _vm.hasAdvanced
                                            ? _vm.eventsForPlayback
                                            : _vm.events.positions
                                          : _vm.eventsForMap),
                                      "filter-current-time":
                                        _vm.filterCurrentTime,
                                      "highlighted-gids":
                                        _vm.state.filters.display.gids,
                                      variant: "thick"
                                    },
                                    on: { "update:legend": _vm.updateLegend }
                                  })
                                : _vm._e(),
                              _vm.mapSubMode === "kdzones"
                                ? _c("KDZoneMap")
                                : _vm._e(),
                              _vm.mapMode === "replay2d" &&
                              _vm.mapSubMode === "agents"
                                ? _c("MapEvents", {
                                    attrs: {
                                      "current-time":
                                        _vm.mapMode === "replay2d"
                                          ? _vm.currentTime
                                          : null,
                                      data: _vm.data,
                                      events:
                                        _vm.mapController._13_events_kills,
                                      "filter-current-time":
                                        _vm.filterCurrentTime,
                                      selected: _vm.selected,
                                      variant: "kills"
                                    }
                                  })
                                : _vm._e(),
                              _vm.mapSubMode !== "kdzones"
                                ? _c("MapEvents", {
                                    attrs: {
                                      data: _vm.data,
                                      events:
                                        _vm.mapMode === "replay2d"
                                          ? _vm.spikesForReplay
                                          : (_vm.events && _vm.events.plants) ||
                                            [],
                                      variant: "spikes"
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      false && _vm.mapSubMode !== "agents"
                        ? _c("SvgLegend", {
                            attrs: { data: _vm.data, legend: _vm.sub.legend }
                          })
                        : _vm._e(),
                      _vm.drawingConfig &&
                      _vm.drawings &&
                      _vm.mapSubMode !== "kdzones"
                        ? _c("MapDrawing", {
                            ref: "mapDrawing",
                            attrs: {
                              config: _vm.drawingConfig,
                              strokes: _vm.drawings,
                              enabled: _vm.state.drawingEnabled
                            },
                            on: {
                              update: _vm.addDrawing,
                              mousedown: _vm.onMapMouseDown,
                              mousemove: _vm.onMapMouseMove,
                              mouseup: _vm.onMapMouseUp,
                              mousewheel: _vm.onMapMouseWheel,
                              typeText: _vm.onTypeText,
                              erase: _vm.onErase
                            }
                          })
                        : _vm._e(),
                      _vm.mapMode === "analytics" &&
                      _vm.mapSubMode === "heatmap" &&
                      this.state.minimapSelector
                        ? _c("MinimapSelector", {
                            attrs: {
                              events:
                                _vm.events &&
                                (_vm.mapMode === "replay2d"
                                  ? _vm.hasAdvanced
                                    ? _vm.eventsForPlayback
                                    : _vm.events.positions
                                  : _vm.eventsForMap)
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                : _vm._e(),
              _c("div", { staticClass: "clock" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm._f("formatTime")(_vm.currentTime)))
                ])
              ]),
              _vm.textDrawing
                ? _c("div", { staticClass: "drawTextInput" }, [
                    _c("div", { staticClass: "panel" }, [
                      _vm._v(" Type text: "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.textDrawing.text,
                            expression: "textDrawing.text"
                          }
                        ],
                        ref: "drawTextInput",
                        attrs: { type: "text" },
                        domProps: { value: _vm.textDrawing.text },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.textDrawing,
                              "text",
                              $event.target.value
                            )
                          }
                        }
                      }),
                      _c(
                        "div",
                        { staticClass: "buttons" },
                        [
                          _c(
                            "b-button",
                            {
                              on: {
                                click: function($event) {
                                  _vm.textDrawing = null
                                }
                              }
                            },
                            [_vm._v(" Cancel ")]
                          ),
                          _c(
                            "b-button",
                            { on: { click: _vm.onTypeTextDone } },
                            [_vm._v(" Done ")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e(),
              (_vm.mapMode !== "replay2d" || _vm.vodPlayer === "playback") &&
              ((_vm.mapSubMode === "agents" && !_vm.canRender(2500)) ||
                (_vm.mapSubMode === "heatmap" && !_vm.canRender(250000)) ||
                (_vm.mapSubMode &&
                  _vm.mapSubMode.startsWith("traces") &&
                  !_vm.canRender(250000)))
                ? _c("div", { staticClass: "guard-container" }, [
                    _c("div", { staticClass: "guard-content" }, [
                      _c("p", [
                        _vm._v(
                          "This view type may become unresponsive when rendering too many object."
                        )
                      ]),
                      _c("hr"),
                      _c("p", [_vm._v("Please confirm rendering")]),
                      _c(
                        "div",
                        [
                          _c(
                            "b-button",
                            {
                              attrs: { variant: "danger" },
                              on: {
                                click: function($event) {
                                  _vm.forceRender = true
                                }
                              }
                            },
                            [_vm._v(" Render ")]
                          )
                        ],
                        1
                      )
                    ])
                  ])
                : _vm._e()
            ])
          ]
        ),
        _vm.data
          ? _c(
              "div",
              { staticClass: "timeline" },
              [
                _c("TimelineControls", {
                  attrs: { "mute-vod": _vm.muteVod },
                  on: {
                    "update:muteVod": function($event) {
                      return _vm.$emit("update:muteVod", $event)
                    }
                  }
                }),
                _c("Timeline", {
                  attrs: {
                    events: _vm.eventsForTimeline,
                    "user-marks": _vm.state.marks,
                    playable: _vm.playable,
                    "playback-rate": _vm.playbackRate,
                    "supported-playback-rates": _vm.supportedPlaybackRates,
                    playing: _vm.state.playing,
                    "round-duration": _vm.roundDuration,
                    selectable: _vm.selectable,
                    hovered: _vm.hoveredTimelineEvent
                  },
                  on: {
                    "update:playbackRate": function($event) {
                      return _vm.$emit("update:playbackRate", $event)
                    },
                    "update:playing": function($event) {
                      return _vm.$set(_vm.state, "playing", $event)
                    },
                    "update:hovered": function($event) {
                      _vm.hoveredTimelineEvent = $event
                    }
                  }
                })
              ],
              1
            )
          : _vm._e(),
        !_vm.exposed.presenterMode
          ? _c(
              "div",
              { staticClass: "round-selector" },
              [
                _vm._t("rounds", function() {
                  return [_vm._m(0)]
                })
              ],
              2
            )
          : _vm._e()
      ]),
      _c(
        "MapPortal",
        { staticClass: "sidebar-right h-100", attrs: { to: "sidebar-right" } },
        [
          _c(
            "div",
            { staticClass: "submenu" },
            [
              _c("RightSideTabs", {
                attrs: { value: _vm.submenuDisplayType },
                on: {
                  "update:value": function($event) {
                    _vm.submenuDisplayType = $event
                  }
                }
              }),
              _c(
                "V3ScrollableContainer",
                { staticClass: "submenu-content", attrs: { vertical: "" } },
                [
                  _vm.submenuDisplayType === "info"
                    ? [
                        _vm.singleRoundId && _vm.data && _vm.events
                          ? [
                              _vm.mapMode === "replay2d" &&
                              _vm.mapSubMode === "agents"
                                ? _c(
                                    "V3Button",
                                    {
                                      staticClass: "overview",
                                      on: { click: _vm.loadToBoard }
                                    },
                                    [_vm._v(" Snapshot to Board ")]
                                  )
                                : _vm._e(),
                              _c("Killfeed", {
                                attrs: {
                                  agents: _vm.data.agents,
                                  "current-time": !["analytics"].includes(
                                    _vm.mapMode
                                  )
                                    ? _vm.currentTime
                                    : null,
                                  data: _vm.data,
                                  events: _vm.eventsForKillfeed,
                                  expandable: _vm.hasAdvanced,
                                  expanded: _vm.showAbilitiesInKillfeed,
                                  "match-players": _vm.data.matchPlayers,
                                  players: _vm.data.players,
                                  rounds: _vm.data.rounds,
                                  "round-teams": _vm.data.roundTeams,
                                  selected: _vm.selected,
                                  weapons: _vm.data.weapons
                                },
                                on: {
                                  "update:expanded": function($event) {
                                    _vm.showAbilitiesInKillfeed = $event
                                  },
                                  select: _vm.onKillfeedSelect
                                }
                              }),
                              _vm.roundStatsSelectedTeam
                                ? _c("TeamRoundStats", {
                                    attrs: {
                                      data: _vm.data,
                                      team: _vm.roundStatsSelectedTeam
                                    }
                                  })
                                : _vm._e(),
                              _vm.roundStatsEnemyTeam
                                ? _c("TeamRoundStats", {
                                    attrs: {
                                      data: _vm.data,
                                      team: _vm.roundStatsEnemyTeam
                                    }
                                  })
                                : _vm._e()
                            ]
                          : _c("div", [
                              _vm._v("Select a single round to see killfeed")
                            ]),
                        _c("SelectedRoundStatistics", {
                          attrs: { "is-scrim": _vm.isScrim }
                        })
                      ]
                    : _vm._e(),
                  _vm.submenuDisplayType === "filters"
                    ? _c("MapFilters", {
                        attrs: {
                          "has-economy": _vm.hasEconomy,
                          "has-orbs": _vm.hasOrbs,
                          "has-outcome": _vm.hasOutcome,
                          "has-plants": _vm.hasPlants,
                          "has-wins": _vm.hasWins,
                          "is-scrim": _vm.isScrim,
                          "has-abilities-usage": _vm.hasAbilitiesUsage
                        },
                        on: {
                          "new-player-snapshot": _vm.onModalOpen,
                          "new-util-snapshot": function($event) {
                            return _vm.onModalOpen("utility")
                          }
                        }
                      })
                    : _vm._e(),
                  _vm.submenuDisplayType === "xvy"
                    ? [
                        Object.keys(this.data.matches).length === 1
                          ? _c("MapToolRoundXvYSelector", {
                              attrs: {
                                data: _vm.data,
                                selected: _vm.selected,
                                state: this.state
                              },
                              on: { select: _vm.onXvYSelect }
                            })
                          : _c("div", [_vm._v("Not Available")])
                      ]
                    : _vm._e()
                ],
                2
              )
            ],
            1
          ),
          _vm.adsEnabled
            ? _c(
                "div",
                { staticClass: "ad-container" },
                [_c("OverwolfBannerAd")],
                1
              )
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "placeholder" }, [
      _vm._v("This is slot "),
      _c("kbd", [_vm._v("rounds")]),
      _vm._v(". Should provide round selector component here.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }