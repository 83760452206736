var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("g", [
    _vm.config.tool !== "eraser" ? _c("g", { ref: "svg" }) : _vm._e(),
    _c("circle", {
      attrs: { r: "1e10", fill: "transparent", stroke: "transparent" },
      on: {
        mousedown: _vm.onMouseDown,
        mousemove: _vm.onMouseMove,
        mouseup: _vm.onMouseUp,
        mouseleave: _vm.onMouseLeave,
        mouseenter: _vm.onMouseEnter,
        mousewheel: _vm.onMouseWheel,
        touchstart: _vm.onTouchStart,
        touchmove: _vm.onTouchMove,
        touchend: _vm.onTouchEnd
      }
    }),
    _vm.config.tool === "eraser" ? _c("g", { ref: "svg" }) : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }