var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        xmlns: "http://www.w3.org/2000/svg",
        width: "1em",
        height: "1em",
        viewBox: "0 0 24 24"
      }
    },
    [
      _c(
        "g",
        {
          attrs: {
            fill: "none",
            stroke: "currentColor",
            "stroke-linecap": "round",
            "stroke-linejoin": "round",
            "stroke-width": "2"
          }
        },
        [
          _c("rect", {
            attrs: { width: "6", height: "14", x: "2", y: "5", rx: "2" }
          }),
          _c("rect", {
            attrs: { width: "6", height: "10", x: "12", y: "7", rx: "2" }
          }),
          _c("path", { attrs: { d: "M22 2v20" } })
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }