var render = function(_h, _vm) {
  var _c = _vm._c
  return _c(
    "g",
    {
      attrs: {
        transform:
          "rotate(" +
          (_vm.props.rotate || 0) +
          " " +
          _vm.props.x * 1024 +
          " " +
          _vm.props.y * 1024 +
          ")"
      }
    },
    [
      _c("image", {
        class: [_vm.data.attrs.class, _vm.data.staticClass || ""],
        attrs: {
          x: (_vm.props.x - _vm.props.size / 2) * 1024,
          y: (_vm.props.y - _vm.props.size / 2) * 1024,
          href: _vm._f("resolveUrl")(_vm.props.imageUrl),
          height: _vm.props.size * 1024,
          width: _vm.props.size * 1024
        }
      })
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }